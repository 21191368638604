import React from 'react';
import { Nav } from 'react-bootstrap';
import { FaClipboardList, FaUser, FaUsers, FaHome } from 'react-icons/fa';
import '../styles/Sidebar.css';

const Sidebar = ({ activePage, handleMenuClick, userRole }) => {
  return (
    <div className="bg-light sidebar p-1">
      <Nav className="flex-column">
        {userRole === 'admin' && (
          <>
            <Nav.Link
              onClick={() => handleMenuClick('dashboard')}
              className={`nav-link ${activePage === 'dashboard' ? 'active' : ''}`}
            >
              <FaHome className="me-2" /> Dashboard
            </Nav.Link>
            <Nav.Link
              onClick={() => handleMenuClick('donors')}
              className={`nav-link ${activePage === 'donors' ? 'active' : ''}`}
            >
              <FaUser className="me-2" /> Manage Donors
            </Nav.Link>
            <Nav.Link
              onClick={() => handleMenuClick('beneficiaries')}
              className={`nav-link ${activePage === 'beneficiaries' ? 'active' : ''}`}
            >
              <FaUsers className="me-2" /> Manage Beneficiaries
            </Nav.Link>
          </>
        )}
        <Nav.Link
          onClick={() => handleMenuClick('requests')}
          className={`nav-link ${activePage === 'requests' ? 'active' : ''}`}
        >
          <FaClipboardList className="me-2" /> {userRole === 'admin' ? 'Manage Requests' : 'Request for Donation'}
        </Nav.Link>
      </Nav>
    </div>
  );
};

export default Sidebar;
