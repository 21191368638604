import React, { useState, useEffect } from 'react';
import { Button, Form, Alert, Row, Col } from 'react-bootstrap';
import axios from 'axios';
import "../styles/UpdateProfile.css";

const RequestDonation = ({ beneficiaryAadharNumber }) => {
  const [formData, setFormData] = useState({
    todayDate: '',
    donationFor: '',
    details: '',
    amountRequired: '',
    requiredBy: '',
    beneficiaryAadharNumber : ''
  });

  const [alert, setAlert] = useState(null);

  // Set today's date when component mounts
  useEffect(() => {
    const today = new Date().toISOString().split('T')[0]; // YYYY-MM-DD format
    setFormData(prevData => ({
      ...prevData,
      todayDate: today
    }));
  }, []);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      // Prepare payload with correct data types
      const requestPayload = {
        todayDate: formData.todayDate,
        donationFor: formData.donationFor,
        details: formData.details,
        amountRequired: Number(formData.amountRequired), // Convert to number
        requiredBy: formData.requiredBy,
        beneficiaryAadharNumber:"734567543218" // Include beneficiaryAadharNumber in payload
      };

      console.log('Submitting donation request with payload:', requestPayload);

      const response = await axios.post('http://localhost:5000/api//requests/create', requestPayload);

      setAlert({ type: 'success', message: 'Donation request submitted successfully' });
    } catch (error) {
      console.error('Error submitting donation request:', error.response ? error.response.data : error.message);
      setAlert({ type: 'danger', message: 'Failed to submit donation request' });
    }
  };

  return (
    <div className="update-body">
      <div className="update-container">
        <Row className="justify-content-md-center">
          <Col md={8}>
            {alert && <Alert variant={alert.type}>{alert.message}</Alert>}
            <Form onSubmit={handleSubmit}>
              <Form.Group controlId="todayDate" className="mb-3">
                <Form.Label>Today's Date</Form.Label>
                <Form.Control
                  type="date"
                  name="todayDate"
                  value={formData.todayDate}
                  readOnly
                  className="form-control-custom"
                />
              </Form.Group>
              <Form.Group controlId="donationFor" className="mb-3">
                <Form.Label>Donation Needed For</Form.Label>
                <Form.Control
                  as="select"
                  name="donationFor"
                  value={formData.donationFor}
                  onChange={handleChange}
                  required
                  className="form-control-custom"
                >
                  <option value="">Select...</option>
                  <option value="Medical">Medical</option>
                  <option value="Education">Education</option>
                  <option value="Food">Food</option>
                </Form.Control>
              </Form.Group>
              <Form.Group controlId="details" className="mb-3">
                <Form.Label>Details</Form.Label>
                <Form.Control
                  as="textarea"
                  name="details"
                  value={formData.details}
                  onChange={handleChange}
                  placeholder={
                    formData.donationFor === 'Medical'
                      ? 'Disease, Hospitalization status, Doctor Name'
                      : formData.donationFor === 'Education'
                      ? 'Name of the School/College, Course Details, Standard/Year, Total Fees, Balance Fees'
                      : 'Provide relevant details'
                  }
                  className="form-control-custom"
                  required
                />
              </Form.Group>
              <Form.Group controlId="amountRequired" className="mb-3">
                <Form.Label>Amount Required</Form.Label>
                <Form.Control
                  type="number"
                  name="amountRequired"
                  value={formData.amountRequired}
                  onChange={handleChange}
                  required
                  className="form-control-custom"
                />
              </Form.Group>
              <Form.Group controlId="requiredBy" className="mb-3">
                <Form.Label>Required By</Form.Label>
                <Form.Control
                  type="date"
                  name="requiredBy"
                  value={formData.requiredBy}
                  onChange={handleChange}
                  required
                  className="form-control-custom"
                />
              </Form.Group>
              <div className="d-flex justify-content-center">
                <Button variant="primary" type="submit" className="request-button">
                  Request Donation
                </Button>
              </div>
            </Form>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default RequestDonation;
