import React from 'react'

const Unauthorized = () => {
  return (
    <div>
      <h1>Unauthorized</h1>
    </div>
  )
}

export default Unauthorized
