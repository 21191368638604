import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Button, Modal, Alert, Spinner, Form } from 'react-bootstrap';
import axios from 'axios';
import DataTable from 'react-data-table-component';
import { FaEye } from 'react-icons/fa';

const ManageRequests = () => {
  const [requests, setRequests] = useState([]);
  const [filteredRequests, setFilteredRequests] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [currentRequest, setCurrentRequest] = useState({});
  const [alert, setAlert] = useState(null);
  const [loading, setLoading] = useState(true);
  const [search, setSearch] = useState('');

  useEffect(() => {
    const fetchRequests = async () => {
      try {
        const token = localStorage.getItem('token');
        setLoading(true);
        const response = await axios.get('http://localhost:5000/api/requests', {
          headers: { Authorization: `Bearer ${token}` }
        });
        setRequests(response.data);
        setFilteredRequests(response.data);
        setLoading(false);
      } catch (error) {
        setAlert({ type: 'danger', message: 'Error fetching requests. Please try again later.' });
        setLoading(false);
      }
    };

    fetchRequests();
  }, []);

  useEffect(() => {
    const result = requests.filter(request => {
      const requestDate = new Date(request.requestDate).toLocaleDateString();
      const dueDate = new Date(request.requiredBy).toLocaleDateString();
      const searchLower = search.toLowerCase();

      return (
        (request.beneficiary?.fullName || '').toLowerCase().includes(searchLower) ||
        (request.category || '').toLowerCase().includes(searchLower) ||
        (request.details || '').toLowerCase().includes(searchLower) ||
        requestDate.includes(searchLower) ||
        dueDate.includes(searchLower)
      );
    });

    setFilteredRequests(result);
  }, [search, requests]);

  const handleShowModal = (request) => {
    setCurrentRequest(request);
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setCurrentRequest({});
  };

  const columns = [
    { name: 'Beneficiary Name', selector: row => row.beneficiary?.fullName || 'N/A', sortable: true },
    { name: 'Request Date', selector: row => new Date(row.requestDate).toLocaleDateString(), sortable: true },
    { name: 'Category', selector: row => row.category || 'N/A', sortable: true },
    { name: 'Details', selector: row => row.details || 'N/A', sortable: true },
    { name: 'Amount Required', selector: row => row.amountRequired || 'N/A', sortable: true },
    { name: 'Due Date', selector: row => new Date(row.requiredBy).toLocaleDateString(), sortable: true },
    {
      name: 'Actions',
      cell: row => (
        <Button variant="link" onClick={() => handleShowModal(row)}>
          <FaEye />
        </Button>
      ),
      sortable: false,
    }
  ];

  return (
    <>
      <Container className="mt-4">
        {alert && <Alert variant={alert.type}>{alert.message}</Alert>}
        {loading ? (
          <div className="d-flex justify-content-center mt-5">
            <Spinner animation="border" />
          </div>
        ) : (
          <>
            <h2>Manage Requests</h2>
            <Form.Group controlId="search">
              <Form.Control
                type="text"
                placeholder="Search..."
                value={search}
                onChange={(e) => setSearch(e.target.value)}
              />
            </Form.Group>
            <DataTable
              columns={columns}
              data={filteredRequests}
              pagination
              highlightOnHover
              responsive
            />

            <Modal show={showModal} onHide={handleCloseModal}>
              <Modal.Header closeButton>
                <Modal.Title>Request Details</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                {currentRequest && (
                  <>
                    <Row>
                      <Col><strong>Beneficiary Name:</strong> {currentRequest.beneficiary?.fullName || 'N/A'}</Col>
                    </Row>
                    <Row>
                      <Col><strong>Category:</strong> {currentRequest.category || 'N/A'}</Col>
                    </Row>
                    <Row>
                      <Col><strong>Details:</strong> {currentRequest.details || 'N/A'}</Col>
                    </Row>
                    <Row>
                      <Col><strong>Amount Required:</strong> {currentRequest.amountRequired || 'N/A'}</Col>
                    </Row>
                    <Row>
                      <Col><strong>Due Date:</strong> {currentRequest.requiredBy ? new Date(currentRequest.requiredBy).toLocaleDateString() : 'N/A'}</Col>
                    </Row>
                  </>
                )}
              </Modal.Body>
              <Modal.Footer>
                <Button variant="secondary" onClick={handleCloseModal}>Close</Button>
              </Modal.Footer>
            </Modal>
          </>
        )}
      </Container>
    </>
  );
};

export default ManageRequests;
