import React from 'react';
import { Navigate } from 'react-router-dom';

const ProtectedRoute = ({ allowedRoles, children }) => {
  const token = localStorage.getItem('token'); // Retrieve token from localStorage
  const role = localStorage.getItem('role');   // Retrieve role from localStorage

  console.log("User Role:", role);
  console.log("Allowed Roles:", allowedRoles);

  // If user has no token or their role is not in the allowedRoles, navigate to the Unauthorized page
  if (!token || !allowedRoles.includes(role)) {
    return <Navigate to="/unauthorized" replace />;
  }

  // Render the children if the user is authorized
  return children;
};

export default ProtectedRoute;
