import React from 'react';
import { BrowserRouter, Route, Routes, } from 'react-router-dom';

import Login from './Components/Login';
import Profile from './Pages/Profile';
import ForgotPassword from './Components/ForgotPassword';
import ResetPassword from './Components/ResetPassword';
import OTPVerify from './Components/OTPVerify';
import Signup from './Pages/Signup';
import Home from './Pages/Home'; // Home page accessible without roles
import DonorDashboard from './Pages/DonorDashboard';
import RequestersDashboard from './Pages/RequesterDashboard';
import Admin from './AdminPanel/Admin'; 
import ManageBeneficiaries from './AdminPanel/ManageBeneficiaries';
import ManageDonor from './AdminPanel/ManageDonor';
import ManageRequests from './AdminPanel/ManageRequests';
import ProtectedRoute from './Components/ProtectedRoute';
import Unauthorized from './Pages/Unauthorized'; // Optional unauthorized page

const App = () => {
  return (
    <BrowserRouter>
      <Routes>
        {/* Unprotected Routes */}
        <Route path='/' element={<Login />} />
        <Route path='/signup' element={<Signup />} />
        <Route path='/forgot-password' element={<ForgotPassword />} />
        <Route path='/reset-password' element={<ResetPassword />} />
        <Route path='/verify-otp' element={<OTPVerify />} />

        {/* Home page accessible to all */}
        <Route path='/home' element={<Home />} />

        {/* Protected Routes */}
        <Route 
          path='/admin-dashboard' 
          element={
            <ProtectedRoute allowedRoles={['Admin']}>
              <Admin />
            </ProtectedRoute>
          }
        >
          <Route 
            path='manage-donor' 
            element={
              <ProtectedRoute allowedRoles={['Admin']}>
                <ManageDonor />
              </ProtectedRoute>
            } 
          />
          <Route 
            path='manage-beneficiaries' 
            element={
              <ProtectedRoute allowedRoles={['Admin']}>
                <ManageBeneficiaries />
              </ProtectedRoute>
            } 
          />
          <Route 
            path='manage-requests' 
            element={
              <ProtectedRoute allowedRoles={['Admin']}>
                <ManageRequests />
              </ProtectedRoute>
            } 
          />
        </Route>

        <Route 
          path='/profile' 
          element={
            <ProtectedRoute allowedRoles={['Beneficiary', 'Donor', 'Admin']}>
              <Profile />
            </ProtectedRoute>
          } 
        />

        <Route 
          path='/donor-dashboard' 
          element={
            <ProtectedRoute allowedRoles={['Donor']}>
              <DonorDashboard />
            </ProtectedRoute>
          } 
        />

        <Route 
          path='/requester-dashboard' 
          element={
            <ProtectedRoute allowedRoles={['Beneficiary']}>
              <RequestersDashboard />
            </ProtectedRoute>
          } 
        />


        {/* Unauthorized Route */}
        <Route path='/unauthorized' element={<Unauthorized />} />
      </Routes>
    </BrowserRouter>
  );
};

export default App;
