import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Form, Button, Row, Col, InputGroup } from 'react-bootstrap';
import { FaEye, FaEyeSlash } from 'react-icons/fa';
import '../styles/Login.css'; // Import the external CSS file

const Login = () => {
  const [aadharNumber, setAadharNumber] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const navigate = useNavigate();
  
  const handleLogin = async (event) => {
    event.preventDefault();
    setError('');
    setSuccess('');
  
    const loginData = {
      aadharNumber,
      password,
    };
  
    try {
      const response = await fetch('http://localhost:5000/api/login', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(loginData),
      });
  
      if (response.ok) {
        const data = await response.json();
        console.log('Login Response:', data); // Log entire response

        // Store the token, role, and profile information in localStorage
        localStorage.setItem('token', data.token);
        localStorage.setItem('role', data.role);
        localStorage.setItem('fullName', data.fullName || '');
        localStorage.setItem('profileImage', data.profileImage || '');

        // Update the profile image URL state
        const profileImageUrl = data.profileImage 
          ? `http://localhost:5000/${data.profileImage.replace(/\\/g, '/')}`
          : 'https://via.placeholder.com/40';
        localStorage.setItem('profileImageUrl', profileImageUrl);

        setSuccess('Login successful! Redirecting to your dashboard...');
        setTimeout(() => {
          switch (data.role) {
            case 'Admin':
              navigate('/admin-dashboard');
              break;
            case 'Donor':
              navigate('/donor-dashboard');
              break;
            case 'Beneficiary':
              navigate('/requester-dashboard');
              break;
            default:
              setError('Invalid role. Please contact support.');
          }
        }, 2000); // Delay for 2 seconds
      } else {
        const errorData = await response.json();
        setError(errorData.message || 'Login failed. Please try again.');
      }
    } catch (err) {
      console.error('Login error:', err);
      setError('An error occurred. Please try again later.');
    }
  };
  
  return (
    <div className="login-body">
      <div className="login-container">
        <Row className="justify-content-md-center login-wrapper">
          <Col md={12}>
            <h2 className="login-heading">Login</h2>
            <Form onSubmit={handleLogin}>
              <Form.Group controlId="aadharNumber" className="mb-3">
                <Form.Label>Aadhar No</Form.Label>
                <Form.Control
                  type="text"
                  value={aadharNumber}
                  placeholder="Enter Aadhar No"
                  onChange={(e) => setAadharNumber(e.target.value)}
                  required
                  className="form-control-custom"
                />
              </Form.Group>

              <Form.Group controlId="password" className="mb-3 position-relative">
                <Form.Label>Password</Form.Label>
                <InputGroup>
                  <Form.Control
                    type={showPassword ? 'text' : 'password'}
                    value={password}
                    placeholder="Enter Password"
                    onChange={(e) => setPassword(e.target.value)}
                    required
                    className="form-control-custom"
                  />
                  <InputGroup.Text className="number" onClick={() => setShowPassword(!showPassword)}>
                    {showPassword ? <FaEyeSlash /> : <FaEye />}
                  </InputGroup.Text>
                </InputGroup>
              </Form.Group>

              {error && <p className="text-danger">{error}</p>}
              {success && (
                <p className="text-success">
                  {success}
                  <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                </p>
              )}

              <Button variant="primary" type="submit">Login</Button>
              <p className="text-center mt-3">
                <a href="/forgot-password" className="link-custom">Forgot Password?</a>
              </p>
              <p className="text-center">
                Don't have an account? <a href="/signup" className="link-custom">Sign Up</a>
              </p>
            </Form>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default Login;
