import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Nav } from 'react-bootstrap';
import { FaClipboardList } from 'react-icons/fa';
import '../styles/Tabs.css';
import RequestDonation from "../Components/RequestDonation";
import UpdateProfile from '../Components/UpdateProfile/UpadteProfile';
import Header from '../Components/Header';

const RequesterDashboard = () => {
  const [activePage, setActivePage] = useState('requests'); // Default to showing Requests
  const [showProfileForm, setShowProfileForm] = useState(false); // Toggle Update Profile form
  const [role, setRole] = useState(''); // State to store the role
  const [fullName, setFullName] = useState(''); // State to store the full name
  const [profileImage, setProfileImage] = useState(''); // State to store the profile image

  // Fetch role, fullName, and profileImage from localStorage on component mount
  useEffect(() => {
    const userRole = localStorage.getItem('role'); // Fetch the role from localStorage
    const storedFullName = localStorage.getItem('fullName'); // Fetch the full name from localStorage
    const storedProfileImage = localStorage.getItem('profileImage'); // Fetch the profile image from localStorage

    if (userRole) {
      setRole(userRole); // Set the role state
    }
    if (storedFullName) {
      setFullName(storedFullName); // Set the full name state
    }
    if (storedProfileImage) {
      setProfileImage(storedProfileImage); // Set the profile image state
    }
  }, []);

  // Handle Sidebar Navigation
  const handleMenuClick = (page) => {
    setActivePage(page);
    setShowProfileForm(false); // Hide profile form when navigating
  };

  // Handle Update Profile Click
  const handleUpdateProfileClick = () => {
    setShowProfileForm(true); // Show Update Profile form
  };

  return (
    <div>
      {/* Pass role, fullName, and profileImage to Header */}
      <Header 
        role={role} 
        handleUpdateProfileClick={handleUpdateProfileClick} 
        fullName={fullName} 
        profileImage={profileImage} 
      />

      {/* Layout with Sidebar and Main Content */}
      <Container fluid>
        <Row>
          {/* Sidebar */}
          <Col md={2} className="bg-light sidebar p-3">
            <Nav className="flex-column">
              <Nav.Link
                onClick={() => handleMenuClick('requests')}
                className={`nav-link ${activePage === 'requests' ? 'active' : ''}`}
              >
                <FaClipboardList className="me-2" /> Requests for Donation
              </Nav.Link>
              {/* Add more menu items here if needed */}
            </Nav>
          </Col>

          {/* Main Content Area */}
          <Col md={10} className="p-4">
            {/* Show the selected content */}
            {!showProfileForm ? (
              <div>
                <h2 className='update-heading'>Requests for Donation</h2>
                <RequestDonation /> {/* Correctly render the RequestDonation component */}
              </div>
            ) : (
              <div className="update-profile-form">
                <h2 className='update-heading'>Update Profile</h2>
                <UpdateProfile />
              </div>
            )}
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default RequesterDashboard;
