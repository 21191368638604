import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Button, Modal, Alert, Spinner, Form } from 'react-bootstrap';
import axios from 'axios';
import DataTable from 'react-data-table-component';
import { FaEye } from 'react-icons/fa';
import "../styles/DonorDashboard.css"; // Ensure your CSS file is imported
import Header from '../Components/Header';

const DonorDashboard = () => {
  const [requests, setRequests] = useState([]);
  const [selectedBeneficiary, setSelectedBeneficiary] = useState(null);
  const [contactMethod, setContactMethod] = useState('phone');
  const [message, setMessage] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [alert, setAlert] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const token = localStorage.getItem('token');
        setLoading(true);

        // Fetch all requests data
        const response = await axios.get('http://localhost:5000/api/requests', {
          headers: { Authorization: `Bearer ${token}` }
        });

        setRequests(response.data);
        setLoading(false);
      } catch (error) {
        setAlert({ type: 'danger', message: 'Error fetching data. Please try again later.' });
        console.error('Error fetching data:', error.message);
        console.error('Error details:', error.response ? error.response.data : 'No additional error details');
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const handleViewDetails = (beneficiary) => {
    setSelectedBeneficiary(beneficiary);
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setSelectedBeneficiary(null);
    setMessage('');
    setContactMethod('phone');
  };

  const handleContactBeneficiary = async (e) => {
    e.preventDefault();
    try {
      const token = localStorage.getItem('token');
      await axios.post('http://localhost:5000/api/contact', {
        beneficiaryId: selectedBeneficiary.aadharNumber,
        contactMethod,
        message,
      }, {
        headers: { Authorization: `Bearer ${token}` }
      });
      setAlert({ type: 'success', message: 'Message sent successfully' });
      handleCloseModal();
    } catch (error) {
      setAlert({ type: 'danger', message: 'Error sending message. Please try again later.' });
      console.error('Error sending message:', error.message);
      console.error('Error details:', error.response ? error.response.data : 'No additional error details');
    }
  };

  const columns = [
    { name: 'Sno', selector: (row, index) => index + 1, sortable: true },
    {
      name: 'Beneficiary Name',
      selector: row => (
        <span  onClick={() => handleViewDetails(row.beneficiary)}>
          {row.beneficiary.fullName}
          
        </span>
      ),
      sortable: true,
    },
    { name: 'Request Date', selector: row => new Date(row.requestDate).toLocaleDateString(), sortable: true },
    { name: 'Due Date', selector: row => new Date(row.requiredBy).toLocaleDateString(), sortable: true },
    { name: 'Category', selector: row => row.category, sortable: true },
    { name: 'Amount Required', selector: row => row.amountRequired, sortable: true },
   
    {
      name: 'Actions',
      cell: row => (
        <Button variant="link" onClick={() => handleViewDetails(row.beneficiary)}>
          <FaEye />
        </Button>
      ),
      sortable: false,
    }
  ];

  return (
    <>
      <Header />
      <Container className="mt-4">
        {alert && <Alert variant={alert.type}>{alert.message}</Alert>}
        {loading ? (
          <div className="d-flex justify-content-center mt-5">
            <Spinner animation="border" />
          </div>
        ) : (
          <>
            <Row>
              <Col md={12}>
                <h3>View the Requests</h3>
                <DataTable
                  columns={columns}
                  data={requests}
                  pagination
                />

                <Modal show={showModal} onHide={handleCloseModal} size="lg">
                  <Modal.Header closeButton>
                    <Modal.Title>Beneficiary Details</Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    {selectedBeneficiary && (
                      <>
                        <p><strong>Name:</strong> {selectedBeneficiary.fullName}</p>
                        <p><strong>Aadhar Number:</strong> {selectedBeneficiary.aadharNumber}</p>
                        <p><strong>Contact Number:</strong> {selectedBeneficiary.contactNumber}</p>
                        <p><strong>Email:</strong> {selectedBeneficiary.email}</p>
                        <p><strong>Address:</strong> {`${selectedBeneficiary.address}, ${selectedBeneficiary.city}, ${selectedBeneficiary.state}, ${selectedBeneficiary.country}`}</p>
                        <p><strong>Details:</strong> {selectedBeneficiary.details}</p>

                        <Form onSubmit={handleContactBeneficiary}>
                          <Form.Group className="mb-3">
                            <Form.Label>Contact Method</Form.Label>
                            <div>
                              <Form.Check
                                inline
                                type="radio"
                                label="Phone"
                                value="phone"
                                checked={contactMethod === 'phone'}
                                onChange={() => setContactMethod('phone')}
                              />
                              <Form.Check
                                inline
                                type="radio"
                                label="Email"
                                value="email"
                                checked={contactMethod === 'email'}
                                onChange={() => setContactMethod('email')}
                              />
                            </div>
                          </Form.Group>
                          <Form.Group className="custom-message">
                            <Form.Label>Message</Form.Label>
                            <Form.Control
                              as="textarea"
                              rows={3}
                              value={message}
                              onChange={(e) => setMessage(e.target.value)}
                            />
                          </Form.Group>
                          <Button variant="primary" type="submit" className="custom-button">
                            Send Message
                          </Button>
                        </Form>
                      </>
                    )}
                  </Modal.Body>
                  <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseModal}>
                      Close
                    </Button>
                  </Modal.Footer>
                </Modal>
              </Col>
            </Row>
          </>
        )}
      </Container>
    </>
  );
};

export default DonorDashboard;
