import React, { useState, useEffect } from 'react';
import { Button, Modal, Form, Row, Col, Alert as BootstrapAlert, InputGroup, FormControl, Spinner } from 'react-bootstrap';
import { FaEdit, FaPlus } from 'react-icons/fa'; // Importing icons
import axios from 'axios';
import DataTable from 'react-data-table-component';
import '../styles/Login.css';

import './Admin.css'; // Add custom styling
import { FaEye, FaEyeSlash } from 'react-icons/fa';

const ManageDonors = () => {
  const [donors, setDonors] = useState([]);
  const [show, setShow] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [errors, setErrors] = useState({});
  const [alertMessage, setAlertMessage] = useState('');
  const [alertType, setAlertType] = useState('success');
  
  const [search, setSearch] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [filteredDonors, setFilteredDonors] = useState([]);
  const [currentDonor, setCurrentDonor] = useState({
    fullName: '',
    contactNumber: '',
    alternateContactNumber: '',
    aadharNumber: '',
    panCardNumber: '',
    city: '',
    state: '',
    country: '',
    profession: '',
    address: '',
    email: '',
    password: '',
    remark: ''
  });

  const [loading, setLoading] = useState(false); // Add loading state

  useEffect(() => {
    fetchDonors();
  }, []);
  
  useEffect(() => {
    const result = donors.filter(b =>
      (b.fullName && b.fullName.toLowerCase().includes(search.toLowerCase())) ||
      (b.contactNumber && b.contactNumber.toLowerCase().includes(search.toLowerCase())) ||
      (b.aadharNumber && b.aadharNumber.toLowerCase().includes(search.toLowerCase())) ||
      (b.city && b.city.toLowerCase().includes(search.toLowerCase())) ||
      (b.state && b.state.toLowerCase().includes(search.toLowerCase())) ||
      (b.status && b.status.toLowerCase().includes(search.toLowerCase()))
    );
    setFilteredDonors(result);
  }, [search, donors]);
  
  // Axios configuration
  axios.defaults.baseURL = 'http://localhost:5000/api';
  axios.defaults.headers.common['Authorization'] = `Bearer ${localStorage.getItem('token')}`;

  const handleClose = () => setShow(false);
  const handleShow = (donor = {}) => {
    setIsEdit(!!donor._id);
    setCurrentDonor(donor);
    setShow(true);
  };

  const handleSave = async () => {
    setLoading(true); // Set loading to true
    try {
      setErrors({});
      if (isEdit) {
        await axios.put(`/donors/update/${currentDonor._id}`, currentDonor);
        setAlertMessage('Donor updated successfully');
        setAlertType('success');
      } else {
        await axios.post('/donors/add', currentDonor);
        setAlertMessage('Donor added successfully');
        setAlertType('success');
      }
      handleClose();
      fetchDonors();
    } catch (error) {
      setErrors(error.response?.data.errors || {});
      setAlertMessage(error.response?.data.message || 'An error occurred while saving the donor');
      setAlertType('danger');
    } finally {
      setLoading(false); // Set loading to false
    }
  };

  const handleToggleActive = async (donor) => {
    setLoading(true); // Set loading to true
    try {
      await axios.post(`/user/update/status/${donor._id}`, { isActive: !donor.isActive });
      const updatedDonors = donors.map((d) => {
        if (d._id === donor._id) {
          return { ...d, isActive: !d.isActive };
        }
        return d;
      });
      setDonors(updatedDonors);
    } catch (error) {
      setAlertMessage('An error occurred while updating the donor status');
      setAlertType('danger');
    } finally {
      setLoading(false); // Set loading to false
    }
  };

  const fetchDonors = async () => {
    setLoading(true); // Set loading to true
    try {
      const response = await axios.get('/donors');
      setDonors(response.data.donors);
    } catch (error) {
      setAlertMessage('An error occurred while fetching donors');
      setDonors([]);
    } finally {
      setLoading(false); // Set loading to false
    }
  };

  // Define columns for react-data-table-component
  const columns = [
    {
      name: 'S.No',
      selector: (row, index) => index + 1,
      sortable: false,
      width: '60px'
    },
    { name: 'Full Name', selector: row => row.fullName, sortable: true },
    { name: 'Contact Number', selector: row => row.contactNumber, sortable: true },
    { name: 'Alternate Contact Number', selector: row => row.alternateContactNumber, sortable: true },
    { name: 'Aadhar Number', selector: row => row.aadharNumber, sortable: true },
    { name: 'PAN Number', selector: row => row.panCardNumber, sortable: true },
    { name: 'City', selector: row => row.city, sortable: true },
    { name: 'State', selector: row => row.state, sortable: true },
    { name: 'Country', selector: row => row.country, sortable: true },
    { name: 'Address', selector: row => row.address, sortable: true },
    {
      name: 'Status',
      cell: (row) => (
        <span
          style={{
            color: row.isActive ? 'green' : 'red',
            cursor: 'pointer',
            fontWeight: 'bold'
          }}
          onClick={() => handleToggleActive(row)}
        >
          {row.isActive ? 'Active' : 'Inactive'}
        </span>
      ),
      sortable: true
    },
    {
      name: 'Actions',
      cell: (row) => (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <FaEdit
            onClick={() => handleShow(row)}
            style={{ color: 'blue', cursor: 'pointer', marginRight: '10px', fontSize: '1.5em' }}
          />
        </div>
      )
    }
  ];

  return (
    <div className="manage-donors-container">
      <h1>Manage Donors</h1>
      {alertMessage && (
        <BootstrapAlert variant={alertType} onClose={() => setAlertMessage('')} dismissible>
          {alertMessage}
        </BootstrapAlert>
      )}
      <div className="add-donor-icon">
        <FaPlus
          onClick={() => handleShow({})} // Open form for new donor
          style={{ color: 'green', cursor: 'pointer', fontSize: '1.5em' }}
        />
      </div>
      <InputGroup className="mb-3">
        <FormControl
          placeholder="Search Donors"
          value={search}
          onChange={(e) => setSearch(e.target.value)}
        />
      </InputGroup>
      {loading ? ( // Display loading spinner or message
        <div className="text-center">
          <Spinner animation="border" />
        </div>
      ) : (
        <DataTable
          columns={columns}
          data={filteredDonors}
          pagination
          paginationPerPage={8}
          paginationRowsPerPageOptions={[8, 10, 50]}
          defaultSortFieldId={1}
          defaultSortAsc={true}
          responsive
          className="data-table"
        />
      )}
      <Modal show={show} onHide={handleClose} size='lg'>
        <Modal.Header closeButton>
          <Modal.Title>{isEdit ? 'Edit Donor' : 'Add Donor'}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Row>
              <Col md={6}>
                <Form.Group controlId="formFullName">
                  <Form.Label>Full Name</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter full name"
                    value={currentDonor.fullName}
                    onChange={(e) => setCurrentDonor({ ...currentDonor, fullName: e.target.value })}
                    isInvalid={!!errors.fullName}
                    disabled={loading} // Disable input during loading
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.fullName}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group controlId="formEmail">
                  <Form.Label>Email</Form.Label>
                  <Form.Control
                    type="email"
                    placeholder="Enter email"
                    value={currentDonor.email}
                    onChange={(e) => setCurrentDonor({ ...currentDonor, email: e.target.value })}
                    isInvalid={!!errors.email}
                    disabled={loading} // Disable input during loading
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.email}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <Form.Group controlId="formContactNumber">
                  <Form.Label>Contact Number</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter contact number"
                    value={currentDonor.contactNumber}
                    onChange={(e) => setCurrentDonor({ ...currentDonor, contactNumber: e.target.value })}
                    isInvalid={!!errors.contactNumber}
                    disabled={loading} // Disable input during loading
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.contactNumber}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group controlId="formAlternateContactNumber">
                  <Form.Label>Alternate Contact Number</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter alternate contact number"
                    value={currentDonor.alternateContactNumber}
                    onChange={(e) => setCurrentDonor({ ...currentDonor, alternateContactNumber: e.target.value })}
                    isInvalid={!!errors.alternateContactNumber}
                    disabled={loading} // Disable input during loading
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.alternateContactNumber}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <Form.Group controlId="formAadharNumber">
                  <Form.Label>Aadhar Number</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter Aadhar number"
                    value={currentDonor.aadharNumber}
                    onChange={(e) => setCurrentDonor({ ...currentDonor, aadharNumber: e.target.value })}
                    isInvalid={!!errors.aadharNumber}
                    disabled={loading} // Disable input during loading
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.aadharNumber}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group controlId="formPANNumber">
                  <Form.Label>PAN Number</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter PAN number"
                    value={currentDonor.panCardNumber}
                    onChange={(e) => setCurrentDonor({ ...currentDonor, panCardNumber: e.target.value })}
                    isInvalid={!!errors.panCardNumber}
                    disabled={loading} // Disable input during loading
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.panCardNumber}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
            </Row>
            <Row>
            <Col md={6}>
                <Form.Group controlId="formState">
                  <Form.Label>State</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter state"
                    value={currentDonor.state}
                    onChange={(e) => setCurrentDonor({ ...currentDonor, state: e.target.value })}
                    isInvalid={!!errors.state}
                    disabled={loading} // Disable input during loading
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.state}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group controlId="formCity">
                  <Form.Label>City</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter city"
                    value={currentDonor.city}
                    onChange={(e) => setCurrentDonor({ ...currentDonor, city: e.target.value })}
                    isInvalid={!!errors.city}
                    disabled={loading} // Disable input during loading
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.city}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
             
            </Row>
            <Row>
              <Col md={6}>
                <Form.Group controlId="formCountry">
                  <Form.Label>Country</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter country"
                    value={currentDonor.country}
                    onChange={(e) => setCurrentDonor({ ...currentDonor, country: e.target.value })}
                    isInvalid={!!errors.country}
                    disabled={loading} // Disable input during loading
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.country}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group controlId="formProfession">
                  <Form.Label>Profession</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter profession"
                    value={currentDonor.profession}
                    onChange={(e) => setCurrentDonor({ ...currentDonor, profession: e.target.value })}
                    isInvalid={!!errors.profession}
                    disabled={loading} // Disable input during loading
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.profession}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
             
            </Row>
            <Row>
              
              <Col md={6}>
                <Form.Group controlId="formAddress">
                  <Form.Label>Address</Form.Label>
                  <Form.Control
                    type="text"
                    as="textarea"
                    placeholder="Enter address"
                    value={currentDonor.address}
                    onChange={(e) => setCurrentDonor({ ...currentDonor, address: e.target.value })}
                    isInvalid={!!errors.address}
                    disabled={loading} // Disable input during loading
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.address}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col md={6}>
        <Form.Group controlId="formPassword">
          <Form.Label>Password</Form.Label>
          <InputGroup>
            <Form.Control
              type={showPassword ? 'text' : 'password'}
              placeholder="Enter password"
              value={currentDonor.password}
              onChange={(e) => setCurrentDonor({ ...currentDonor, password: e.target.value })}
              isInvalid={!!errors.password}
              disabled={loading} // Disable input during loading
            />
            <InputGroup.Text 
              className="password-toggle" 
              onClick={() => setShowPassword(!showPassword)}
              style={{ cursor: 'pointer' }}
            >
              {showPassword ? <FaEyeSlash /> : <FaEye />}
            </InputGroup.Text>
          </InputGroup>
          <Form.Control.Feedback type="invalid">
            {errors.password}
          </Form.Control.Feedback>
        </Form.Group>
      </Col>
            </Row>
            <Form.Group controlId="formRemark">
              <Form.Label>Remark</Form.Label>
              <Form.Control
                type="text"
                as= "textarea"
                placeholder="Enter remark"
                value={currentDonor.remark}
                onChange={(e) => setCurrentDonor({ ...currentDonor, remark: e.target.value })}
                isInvalid={!!errors.remark}
                disabled={loading} // Disable input during loading
              />
              <Form.Control.Feedback type="invalid">
                {errors.remark}
              </Form.Control.Feedback>
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose} disabled={loading}>
            Close
          </Button>
          <Button
            variant="primary"
            onClick={handleSave}
            disabled={loading}
          >
            {loading ? <Spinner as="span" animation="border" size="sm" /> : 'Save'}
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default ManageDonors;
