import React from 'react';
import { Form } from 'react-bootstrap';
import "../../styles/UpdateProfile.css";

const PersonalInfoForm = ({ formData, onChange }) => {
  const handleChange = (e) => {
    const { name, value } = e.target;
    onChange({ ...formData, [name]: value });
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    onChange({ ...formData, profileImage: file });
  };

  return (
    <div>
      <Form.Group controlId="fullName">
        <Form.Label>Full Name</Form.Label>
        <Form.Control
          type="text"
          name="fullName"
          value={formData.fullName || ''}
          onChange={handleChange}
          className="form-control-custom"
        />
      </Form.Group>

      <Form.Group controlId="aadharNumber">
        <Form.Label>Aadhar Number</Form.Label>
        <Form.Control
          type="text"
          name="aadharNumber"
          value={formData.aadharNumber || ''}
          onChange={handleChange}
          className="form-control-custom"
        />
      </Form.Group>

      <Form.Group controlId="contactNumber">
        <Form.Label>Contact Number</Form.Label>
        <Form.Control
          type="text"
          name="contactNumber"
          value={formData.contactNumber || ''}
          onChange={handleChange}
          className="form-control-custom"
        />
      </Form.Group>

      <Form.Group controlId="alternateContactNumber">
        <Form.Label>Alternate Contact Number</Form.Label>
        <Form.Control
          type="text"
          name="alternateContactNumber"
          value={formData.alternateContactNumber || ''}
          onChange={handleChange}
          className="form-control-custom"
        />
      </Form.Group>

      <Form.Group controlId="address">
        <Form.Label>Address</Form.Label>
        <Form.Control
          type="text"
          name="address"
          value={formData.address || ''}
          onChange={handleChange}
          className="form-control-custom"
        />
      </Form.Group>

      <Form.Group controlId="city">
        <Form.Label>City</Form.Label>
        <Form.Control
          type="text"
          name="city"
          value={formData.city || ''}
          onChange={handleChange}
          className="form-control-custom"
        />
      </Form.Group>

      <Form.Group controlId="state">
        <Form.Label>State</Form.Label>
        <Form.Control
          type="text"
          name="state"
          value={formData.state || ''}
          onChange={handleChange}
          className="form-control-custom"
        />
      </Form.Group>

      <Form.Group controlId="country">
        <Form.Label>Country</Form.Label>
        <Form.Control
          type="text"
          name="country"
          value={formData.country || ''}
          onChange={handleChange}
          className="form-control-custom"
        />
      </Form.Group>

      {/* Date of Birth Field */}
      <Form.Group controlId="dob">
        <Form.Label>Date of Birth</Form.Label>
        <Form.Control
          type="date"
          name="dob"
          value={formData.dob || ''}
          onChange={handleChange}
          className="form-control-custom"
        />
      </Form.Group>

      {/* Gender Radio Buttons */}
      <Form.Group controlId="gender">
        <Form.Label>Gender</Form.Label>
        <div>
          <Form.Check
            type="radio"
            id="male"
            name="gender"
            label="Male"
            value="Male"
            checked={formData.gender === "Male"}
            onChange={handleChange}
            className="form-check-inline"
          />
          <Form.Check
            type="radio"
            id="female"
            name="gender"
            label="Female"
            value="Female"
            checked={formData.gender === "Female"}
            onChange={handleChange}
            className="form-check-inline"
          />
        </div>
      </Form.Group>

      {/* Image Upload Field */}
      <Form.Group controlId="profileImage">
        <Form.Label>Profile Image</Form.Label>
        <Form.Control
          type="file"
          name="profileImage"
          accept="image/*"
          onChange={handleImageChange}
          className="form-control-custom"
        />
      </Form.Group>
    </div>
  );
};

export default PersonalInfoForm;
