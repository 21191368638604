import React from 'react';
import { Form } from 'react-bootstrap';
import "../../styles/UpdateProfile.css";

const GovernmentSchemesForm = ({ formData, onChange }) => {
  const handleRadioChange = (e) => {
    const { name, value } = e.target;
    onChange({
      ...formData,
      [name]: value,
      [`${name}Details`]: value === 'No' ? '' : formData[`${name}Details`],
    });
  };

  const handleDetailsChange = (e) => {
    const { name, value } = e.target;
    onChange({
      ...formData,
      [name]: value,
    });
  };

  return (
    <>
    {/* Samagra ID Section */}
    <Form.Group controlId="samagraID">
        <Form.Label>Do You Have samagraID?</Form.Label>
        <Form.Check
          type="radio"
          name="samagraID"
          label="Yes"
          value="Yes"
          checked={formData.samagraID === 'Yes'}
          onChange={handleRadioChange}
        />
        <Form.Check
          type="radio"
          name="samagraID"
          label="No"
          value="No"
          checked={formData.samagraID === 'No'}
          onChange={handleRadioChange}
        />
        {formData.samagraID === 'Yes' && (
          <Form.Control
            type="text"
            name="samagraidDetails"
            value={formData.samagraidDetails || ''}
            onChange={handleDetailsChange}
            placeholder="Enter Samagra id details"
          />
        )}
      </Form.Group>

      {/* Ayushman Card Section */}
      <Form.Group controlId="ayushmanCard">
        <Form.Label>Do You Have Ayushman Card?</Form.Label>
        <Form.Check
          type="radio"
          name="ayushmanCard"
          label="Yes"
          value="Yes"
          checked={formData.ayushmanCard === 'Yes'}
          onChange={handleRadioChange}
        />
        <Form.Check
          type="radio"
          name="ayushmanCard"
          label="No"
          value="No"
          checked={formData.ayushmanCard === 'No'}
          onChange={handleRadioChange}
        />
        {formData.ayushmanCard === 'Yes' && (
          <Form.Control
            type="text"
            name="ayushmanCardDetails"
            value={formData.ayushmanCardDetails || ''}
            onChange={handleDetailsChange}
            placeholder="Enter Ayushman Card details"
          />
        )}
      </Form.Group>
      {/* Ration Card Section */}
<Form.Group controlId="rationCard">
        <Form.Label>Are you have Ration Card?</Form.Label>
        <Form.Check
          type="radio"
          name="rationCard"
          label="Yes"
          value="Yes"
          checked={formData.rationCard === 'Yes'}
          onChange={handleRadioChange}
        />
        <Form.Check
          type="radio"
          name="rationCard"
          label="No"
          value="No"
          checked={formData.rationCard === 'No'}
          onChange={handleRadioChange}
        />
        {formData.rationCard === 'Yes' && (
          <Form.Control
            type="text"
            name="rationCardDetails"
            value={formData.rationCardDetails || ''}
            onChange={handleDetailsChange}
            placeholder="Enter Ration Card details"
          />
        )}
      </Form.Group>

      {/* Minority certificate Section */}
      <Form.Group controlId="minorityCerificate">
        <Form.Label>Are you have Minority Cerificate ?</Form.Label>
        <Form.Check
          type="radio"
          name="minorityCertificate"
          label="Yes"
          value="Yes"
          checked={formData.minorityCertificate === 'Yes'}
          onChange={handleRadioChange}
        />
        <Form.Check
          type="radio"
          name="minorityCertificate"
          label="No"
          value="No"
          checked={formData.minorityCertificate === 'No'}
          onChange={handleRadioChange}
        />
        {formData.minorityCertificate === 'Yes' && (
          <Form.Control
            type="text"
            name="minorityCertificateDetails"
            value={formData.minorityCertificateDetails || ''}
            onChange={handleDetailsChange}
            placeholder="Enter Minority Certificate Details"
          />
        )}
      </Form.Group>


      {/* Widow Pension Section */}
      <Form.Group controlId="widowPension">
        <Form.Label>Are you getting a Widow Pension?</Form.Label>
        <Form.Check
          type="radio"
          name="widowPension"
          label="Yes"
          value="Yes"
          checked={formData.widowPension === 'Yes'}
          onChange={handleRadioChange}
        />
        <Form.Check
          type="radio"
          name="widowPension"
          label="No"
          value="No"
          checked={formData.widowPension === 'No'}
          onChange={handleRadioChange}
        />
        {formData.widowPension === 'Yes' && (
          <Form.Control
            type="text"
            name="widowPensionDetails"
            value={formData.widowPensionDetails || ''}
            onChange={handleDetailsChange}
            placeholder="Enter Widow Pension details"
          />
        )}
      </Form.Group>

      {/* Senior Citizen Pension Section */}
      <Form.Group controlId="seniorCitizenPension">
        <Form.Label>Are you getting a Senior Citizen Pension?</Form.Label>
        <Form.Check
          type="radio"
          name="seniorCitizenPension"
          label="Yes"
          value="Yes"
          checked={formData.seniorCitizenPension === 'Yes'}
          onChange={handleRadioChange}
        />
        <Form.Check
          type="radio"
          name="seniorCitizenPension"
          label="No"
          value="No"
          checked={formData.seniorCitizenPension === 'No'}
          onChange={handleRadioChange}
        />
        {formData.seniorCitizenPension === 'Yes' && (
          <Form.Control
            type="text"
            name="seniorCitizenPensionDetails"
            value={formData.seniorCitizenPensionDetails || ''}
            onChange={handleDetailsChange}
            placeholder="Enter Senior Citizen Pension details"
          />
        )}
      </Form.Group>

      {/* Government Scheme Section */}
      <Form.Group controlId="governmentScheme">
        <Form.Label>Are you taking benefit of any Government Scheme?</Form.Label>
        <Form.Check
          type="radio"
          name="governmentScheme"
          label="Yes"
          value="Yes"
          checked={formData.governmentScheme === 'Yes'}
          onChange={handleRadioChange}
        />
        <Form.Check
          type="radio"
          name="governmentScheme"
          label="No"
          value="No"
          checked={formData.governmentScheme === 'No'}
          onChange={handleRadioChange}
        />
        {formData.governmentScheme === 'Yes' && (
          <Form.Control
            type="text"
            name="governmentSchemeDetails"
            value={formData.governmentSchemeDetails || ''}
            onChange={handleDetailsChange}
            placeholder="Enter Government Scheme details"
          />
        )}
      </Form.Group>
    </>
  );
};

export default GovernmentSchemesForm;
