import React, { useState, useEffect } from 'react';
import { Container, Button, Form, Alert } from 'react-bootstrap';
import PersonalInfoForm from './PersonalInfoForm';
import FamilyDetailsForm from './FamilyDetailsForm';
import OccupationForm from './OccupationForm';
import GovernmentSchemesForm from './GovermentSchemesForm';
import axios from 'axios';

const UpdateProfile = () => {
  const [currentStep, setCurrentStep] = useState(1);
  const [formData, setFormData] = useState({
    personalInfo: {},
    familyDetails: {},
    occupationDetails: {},
    governmentSchemes: {}
  });
  const [alert, setAlert] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchProfileData = async () => {
      try {
        const response = await axios.get('http://localhost:5000/api/user/profile', {
          headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
        });

        console.log('API Response:', response.data); // Debugging

        setFormData({
          personalInfo: response.data,
          familyDetails: response.data ,
          occupationDetails: response.data,
          governmentSchemes: response.data
        });
      } catch (error) {
        console.error('Error fetching profile data:', error);
        setAlert({ type: 'danger', message: 'Failed to fetch profile data' });
      }
    };

    fetchProfileData();
  }, []);

  const handleNextStep = (e) => {
    e.preventDefault();
    resetAlert(); // Reset alert when navigating
    setCurrentStep(currentStep + 1);
  };

  const handlePrevStep = (e) => {
    e.preventDefault();
    resetAlert(); // Reset alert when navigating
    setCurrentStep(currentStep - 1);
  };

  const handleFormDataChange = (section, data) => {
    console.log(`Data for ${section}:`, data); // Debugging
    setFormData(prevFormData => ({
      ...prevFormData,
      [section]: data
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      const formDataToSend = new FormData();

      // Append profile image if provided
      if (formData.personalInfo.profileImage) {
        formDataToSend.append('profileImage', formData.personalInfo.profileImage);
      }

      // Append other form data
      formDataToSend.append('personalInfo', JSON.stringify(formData.personalInfo));
      formDataToSend.append('familyDetails', JSON.stringify(formData.familyDetails));
      formDataToSend.append('occupationDetails', JSON.stringify(formData.occupationDetails));
      formDataToSend.append('governmentSchemes', JSON.stringify(formData.governmentSchemes));

      const response = await axios.post('http://localhost:5000/api/update-profile', formDataToSend, {
        headers: { 
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
          'Content-Type': 'multipart/form-data'
        }
      });
      setAlert({ type: 'success', message: 'Profile updated successfully' });
    } catch (error) {
      console.error('Error updating profile:', error);
      setAlert({ type: 'danger', message: 'Failed to update profile' });
    } finally {
      setLoading(false);
    }
  };

  const resetAlert = () => {
    setAlert(null); // Clear the alert
  };

  return (
    <Container>
      {alert && <Alert variant={alert.type}>{alert.message}</Alert>}
      <Form onSubmit={handleSubmit}>
        {currentStep === 1 && 
          <PersonalInfoForm 
            formData={formData.personalInfo} 
            onChange={(data) => handleFormDataChange('personalInfo', data)} 
          />
        }
        {currentStep === 2 && 
          <FamilyDetailsForm 
            formData={formData.familyDetails} 
            onChange={(data) => handleFormDataChange('familyDetails', data)} 
          />
        }
        {currentStep === 3 && 
          <OccupationForm 
            formData={formData.occupationDetails} 
            onChange={(data) => handleFormDataChange('occupationDetails', data)} 
          />
        }
        {currentStep === 4 && 
          <GovernmentSchemesForm 
            formData={formData.governmentSchemes} 
            onChange={(data) => handleFormDataChange('governmentSchemes', data)} 
          />
        }

        <div className="d-flex justify-content-between">
          {currentStep > 1 && (
            <Button variant="secondary" onClick={handlePrevStep}>
              Back
            </Button>
          )}
          {currentStep < 4 ? (
            <Button variant="primary" onClick={handleNextStep}>
              Next
            </Button>
          ) : (
            <Button variant="primary" type="submit" disabled={loading}>
              {loading ? 'Updating...' : 'Update Profile'}
            </Button>
          )}
        </div>
      </Form>
    </Container>
  );
};

export default UpdateProfile;
