import React from 'react';
import { Form } from 'react-bootstrap';
import "../../styles/UpdateProfile.css";
const OccupationForm = ({ formData, onChange }) => {
  
  const handleChange = (e) => {
    const { name, value } = e.target;
    onChange({ ...formData, [name]: value });
  };

  const handleRadioChange = (e) => {
    const { name, value } = e.target;
    const updatedData = {
      ...formData,
      [name]: value,
      loanDetails: value === 'No' ? '' : formData.loanDetails, // Clear loanDetails if "No" is selected
    };
    onChange(updatedData); // Pass updated data to parent
  };

  return (
    <div>
      <Form.Group controlId="profession">
        <Form.Label>Profession</Form.Label>
        <Form.Control
          type="text"
          name="profession"
          value={formData.profession || ''}
          onChange={handleChange}
          placeholder="Enter your profession"
          required
           className="form-control-custom"
        />
      </Form.Group>

      <Form.Group controlId="designation">
        <Form.Label>Designation</Form.Label>
        <Form.Control
          type="text"
          name="designation"
          value={formData.designation || ''}
          onChange={handleChange}
          placeholder="Enter your designation"
          required
           className="form-control-custom"
        />
      </Form.Group>

      <Form.Group controlId="organization">
        <Form.Label>Organization</Form.Label>
        <Form.Control
          type="text"
          name="organization"
          value={formData.organization || ''}
          onChange={handleChange}
          placeholder="Enter your organization"
          required
           className="form-control-custom"
        />
      </Form.Group>

      <Form.Group controlId="organizationAddress">
        <Form.Label>Organization Address</Form.Label>
        <Form.Control
          type="text"
          name="organizationAddress"
          value={formData.organizationAddress || ''}
          onChange={handleChange}
          placeholder="Enter your organization address"
          required
           className="form-control-custom"
        />
      </Form.Group>

      <Form.Group controlId="annualIncome">
        <Form.Label>Annual Income</Form.Label>
        <Form.Control
          type="text"
          name="annualIncome"
          value={formData.annualIncome || ''}
          onChange={handleChange}
          placeholder="Enter your annual income"
          required
           className="form-control-custom"
        />
      </Form.Group>

      <Form.Group controlId="monthlyIncome">
        <Form.Label>Monthly Income</Form.Label>
        <Form.Control
          type="text"
          name="monthlyIncome"
          value={formData.monthlyIncome || ''}
          onChange={handleChange}
          placeholder="Enter your monthly income"
          required
           className="form-control-custom"
        />
      </Form.Group>

      <Form.Group controlId="loan">
        <Form.Label>Do You Have any Loans?</Form.Label>
        <Form.Check
          type="radio"
          name="loan"
          label="Yes"
          value="Yes"
          checked={formData.loan === 'Yes'}
          onChange={handleRadioChange}
        />
        <Form.Check
          type="radio"
          name="loan"
          label="No"
          value="No"
          checked={formData.loan === 'No'}
          onChange={handleRadioChange}
        />
        {formData.loan === 'Yes' && (
          <Form.Group controlId="loanDetails">
            <Form.Label>Loan Details</Form.Label>
            <Form.Control
              type="text"
              name="loanDetails"
              value={formData.loanDetails || ''}
              onChange={handleChange}
              placeholder="Enter loan details"
              required
               className="form-control-custom"
            />
          </Form.Group>
        )}
      </Form.Group>
    </div>
  );
};

export default OccupationForm;
