import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import {Row,Col,Form} from 'react-bootstrap';
import "../styles/ForgotPassword.css";
const VerifyOTP = () => {
  const [otp, setOtp] = useState('');
  const [message, setMessage] = useState('');
  const [timeRemaining, setTimeRemaining] = useState(15 * 60); // 15 minutes
  const [resendEnabled, setResendEnabled] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (timeRemaining > 0) {
      const timer = setInterval(() => {
        setTimeRemaining(prevTime => prevTime - 1);
      }, 1000);
      return () => clearInterval(timer);
    } else {
      setResendEnabled(true);
    }
  }, [timeRemaining]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post('http://localhost:5000/api/verify-otp', {
        
        otp,
        // Include any other necessary data here
      });
      setMessage(response.data.message);
      localStorage.setItem('resetToken', response.data.token);
      navigate('/reset-password');
    } catch (error) {
      setMessage(error.response.data.message);
    }
  };

  const handleResend = async () => {
    try {
      const response = await axios.post('http://localhost:5000/api/resend-otp', {});
      setMessage(response.data.message);
      setTimeRemaining(15 * 60); // Reset timer
      setResendEnabled(false);
    } catch (error) {
      setMessage(error.response.data.message);
    }
  };

  return (
    <div className='otp-body'>
    <div className="otp-container">
    <Row className="justify-content-md-center">
    <Col md={12}>
      <h2 className='otp-heading'>Verify OTP</h2>
      <Form onSubmit={handleSubmit}>
        <Form.Group className="form-group">
          <Form.Label>OTP</Form.Label>
          <Form.Control
            type="text"
            className="form-control-custom"
            value={otp}
            onChange={(e) => setOtp(e.target.value)}
            required
          />
        </Form.Group>
        <button type="submit" className="btn btn-primary">
          Verify OTP
        </button>
      </Form>
      {message && <p>{message}</p>}
      <p>Time Remaining: {Math.floor(timeRemaining / 60)}:{('0' + (timeRemaining % 60)).slice(-2)}</p>
      {resendEnabled && (
        <button className="btn btn-secondary" onClick={handleResend}>
          Resend OTP
        </button>
      )}
       </Col>
       </Row>
    </div>
    </div>
  );
};

export default VerifyOTP;
