import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Card, Button, Offcanvas } from 'react-bootstrap';
import { FaUser, FaUsers, FaListAlt, FaBars } from 'react-icons/fa';
import ManageDonors from './ManageDonor';
import ManageBeneficiaries from './ManageBeneficiaries';
import ManageRequests from './ManageRequests';
import './Admin.css';

import Header from '../Components/Header';
import Sidebar from '../Components/Sidebar';
import { useLocation, useNavigate } from 'react-router-dom';

const AdminDashboard = () => {
  const location = useLocation();
  const navigate = useNavigate();
  
  // Extract active page from URL query parameter or default to 'dashboard'
  const queryParams = new URLSearchParams(location.search);
  const initialPage = queryParams.get('page') || 'dashboard';

  const [activePage, setActivePage] = useState(initialPage);
  const [showSidebar, setShowSidebar] = useState(false);

  useEffect(() => {
    // Update active page based on URL parameter
    const queryParams = new URLSearchParams(location.search);
    setActivePage(queryParams.get('page') || 'dashboard');
  }, [location]);

  const handleMenuClick = (page) => {
    setActivePage(page);
    navigate(`/admin-dashboard?page=${page}`); // Update URL with selected page
    setShowSidebar(false); // Close sidebar when a menu option is selected
  };

  const handleSidebarToggle = () => {
    setShowSidebar(!showSidebar); // Toggle sidebar visibility
  };

  const renderPageContent = () => {
    switch (activePage) {
      case 'donors':
        return <ManageDonors />;
      case 'beneficiaries':
        return <ManageBeneficiaries />;
      case 'requests':
        return <ManageRequests />;
      default:
        return (
          <Row className="my-4">
            <Col xs={12} md={4} className="mb-4">
              <Card className="text-center bg-primary text-white" onClick={() => handleMenuClick('donors')}>
                <Card.Body>
                  <FaUser size={50} />
                  <Card.Title className="mt-3">Manage Donors</Card.Title>
                </Card.Body>
              </Card>
            </Col>
            <Col xs={12} md={4} className="mb-4">
              <Card className="text-center bg-success text-white" onClick={() => handleMenuClick('beneficiaries')}>
                <Card.Body>
                  <FaUsers size={50} />
                  <Card.Title className="mt-3">Manage Beneficiaries</Card.Title>
                </Card.Body>
              </Card>
            </Col>
            <Col xs={12} md={4} className="mb-4">
              <Card className="text-center bg-warning text-white" onClick={() => handleMenuClick('requests')}>
                <Card.Body>
                  <FaListAlt size={50} />
                  <Card.Title className="mt-3">Manage Requests</Card.Title>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        );
    }
  };

  return (
    <Container fluid>
      <Header />

      {/* Hamburger Menu Button for Mobile/Tablet */}
      <Button variant="link" className="hamburger-menu d-md-none mb-3" onClick={handleSidebarToggle}>
        <FaBars size={30} />
      </Button>

      {/* Offcanvas Sidebar for Mobile/Tablet */}
      <Offcanvas show={showSidebar} onHide={handleSidebarToggle} placement="end">
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>Menu</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <Sidebar userRole="admin" activePage={activePage} handleMenuClick={handleMenuClick} />
        </Offcanvas.Body>
      </Offcanvas>

      <Row>
        {/* Sidebar for Desktop */}
        <Col xs={12} md={2} className="d-none d-md-block">
          <Sidebar userRole="admin" activePage={activePage} handleMenuClick={handleMenuClick} />
        </Col>

        {/* Main Content Area */}
        <Col xs={12} md={10}>
          {renderPageContent()}
        </Col>
      </Row>
    </Container>
  );
};

export default AdminDashboard;
