import React from 'react';
import { Form } from 'react-bootstrap';
import "../../styles/UpdateProfile.css";

const FamilyDetailsForm = ({ formData, onChange }) => {
  console.log(formData);
  const handleChange = (e) => {

    const { name, value } = e.target;
    onChange({ ...formData, [name]: value });
   
}

  return (
    <>
      <Form.Group controlId="fatherName">
        <Form.Label>Father's Name</Form.Label>
        <Form.Control
          type="text"
          name="fatherName"
          value={formData.fatherName || ''}
          onChange={handleChange}
          placeholder="Enter your father's name"
          required
           className="form-control-custom"
        />
      </Form.Group>
      <Form.Group controlId="fatherAadharNumber">
        <Form.Label>Father's Aadhar Number</Form.Label>
        <Form.Control
          type="text"
          name="fatherAadharNumber"
          value={formData.fatherAadharNumber || ''}
          onChange={handleChange}
          placeholder="Enter your father's Aadhar number"
          required
           className="form-control-custom"
        />
      </Form.Group>
      <Form.Group controlId="fatherContactNumber">
        <Form.Label>Father's Contact Number</Form.Label>
        <Form.Control
          type="text"
          name="fatherContactNumber"
          value={formData.fatherContactNumber || ''}
          onChange={handleChange}
          placeholder="Enter your father's contact number"
          maxLength={10}
          required
           className="form-control-custom"
        />
      </Form.Group>
      <Form.Group controlId="motherName">
        <Form.Label>Mother's Name</Form.Label>
        <Form.Control
          type="text"
          name="motherName"
          value={formData.motherName || ''}
          onChange={handleChange}
          placeholder="Enter your mother's name"
          required
           className="form-control-custom"
        />
      </Form.Group>
      <Form.Group controlId="motherAadharNumber">
        <Form.Label>Mother's Aadhar Number</Form.Label>
        <Form.Control
          type="text"
          name="motherAadharNumber"
          value={formData.motherAadharNumber || ''}
          onChange={handleChange}
          placeholder="Enter your mother's Aadhar number"
          required
           className="form-control-custom"
        />
      </Form.Group>
      <Form.Group controlId="motherContactNumber">
        <Form.Label>Mother's Contact Number</Form.Label>
        <Form.Control
          type="text"
          name="motherContactNumber"
          value={formData.motherContactNumber || ''}
          onChange={handleChange}
          placeholder="Enter your mother's contact number"
          maxLength={10}
          required
           className="form-control-custom"
        />
      </Form.Group>
      <Form.Group controlId="brotherName">
        <Form.Label>Brother Name</Form.Label>
        <Form.Control
          type="text"
          name="brotherName"
          value={formData.brotherName || ''}
          onChange={handleChange}
          placeholder="Enter Brother Name"
          
          required
           className="form-control-custom"
        />
      </Form.Group>
      <Form.Group controlId="brotherAadharNumber">
        <Form.Label>Brother's Aadhar Number</Form.Label>
        <Form.Control
          type="text"
          name="brotherAadharNumber"
          value={formData.motherAadharNumber || ''}
          onChange={handleChange}
          placeholder="Enter your brother's Aadhar number"
          required
           className="form-control-custom"
        />
      </Form.Group>
      <Form.Group controlId="brotherContactNumber">
        <Form.Label>Brother's Contact Number</Form.Label>
        <Form.Control
          type="text"
          name="brotherContactNumber"
          value={formData.brotherContactNumber || ''}
          onChange={handleChange}
          placeholder="Enter your brother's contact number"
          maxLength={10}
          required
           className="form-control-custom"
        />
      </Form.Group>
      <Form.Group controlId="sisterName">
        <Form.Label>Sister Name</Form.Label>
        <Form.Control
          type="text"
          name="sirsterName"
          value={formData.sisterName || ''}
          onChange={handleChange}
          placeholder="Enter Sister Name"

          
          required
           className="form-control-custom"
        />
      </Form.Group>
      <Form.Group controlId="sisterAadharNumber">
        <Form.Label>Sister's Aadhar Number</Form.Label>
        <Form.Control
          type="text"
          name="sisterAadharNumber"
          value={formData.sisterAadharNumber || ''}
          onChange={handleChange}
          placeholder="Enter your sister's Aadhar number"
          required
           className="form-control-custom"
        />
      </Form.Group>
      <Form.Group controlId="sisterContactNumber">
        <Form.Label>Sister's Contact Number</Form.Label>
        <Form.Control
          type="text"
          name="sisterContactNumber"
          value={formData.sisterContactNumber || ''}
          onChange={handleChange}
          placeholder="Enter your sister's contact number"
          maxLength={10}
          required
           className="form-control-custom"
        />
      </Form.Group>
      <Form.Group controlId="childName1">
        <Form.Label>Child Name 1</Form.Label>
        <Form.Control
          type="text"
          name="childName1"
          value={formData.childName1 || ''}
          onChange={handleChange}
          placeholder="Enter Child Name"
          
          required
           className="form-control-custom"
        />
      </Form.Group>
      <Form.Group controlId="childName2">
        <Form.Label>Child Name 2</Form.Label>
        <Form.Control
          type="text"
          name="childName2"
          value={formData.childName2 || ''}
          onChange={handleChange}
          placeholder="Enter Child Name"
          
          required
           className="form-control-custom"
        />
      </Form.Group>
      
    </>
  );
};

export default FamilyDetailsForm;
