import React, { useState, useEffect } from 'react';
import { Dropdown } from 'react-bootstrap';
import { FaUser, FaSignOutAlt, FaUserEdit } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import Logo from '../Assests/Donation.png'; // Fixed typo in file path

const Header = ({ handleUpdateProfileClick }) => {
  const [successMessage, setSuccessMessage] = useState('');
  const navigate = useNavigate();

  // Retrieve data from local storage
  const role = localStorage.getItem('role');
  const fullName = localStorage.getItem('fullName');
  const profileImageUrl = localStorage.getItem('profileImageUrl') || 'https://via.placeholder.com/40'; // Default placeholder

  useEffect(() => {
    // Update the profileImageUrl if not set initially
    if (!localStorage.getItem('profileImageUrl')) {
      const storedProfileImagePath = localStorage.getItem('profileImage');
      const newProfileImageUrl = storedProfileImagePath 
        ? `http://localhost:5000/${storedProfileImagePath.replace(/\\/g, '/')}` 
        : 'https://via.placeholder.com/40';
      localStorage.setItem('profileImageUrl', newProfileImageUrl);
    }
  }, []);

  const handleLogoutClick = () => {
    // Remove token and user data from local storage
    localStorage.removeItem('token');
    localStorage.removeItem('fullName');
    localStorage.removeItem('profileImage');
    localStorage.removeItem('profileImageUrl');
    localStorage.removeItem('role');

    // Set the success message
    setSuccessMessage('Logout successful.');

    // Redirect to login page after showing the message for 2 seconds
    setTimeout(() => {
      navigate('/');
    }); // 2-second delay before redirection
  };

  return (
    <header className="admin-header bg-primary text-white p-3 d-flex justify-content-between align-items-center">
      {/* Logo Image */}
      <img
        src={Logo}
        alt="logo"
        className="logo"
        style={{ height: '60px', width: '60px' }}
      />

      <div className="user-profile d-flex align-items-center">
        {/* Only show profile image and update profile for Beneficiaries */}
        {role === 'Beneficiary' && (
          <>
            <img
              src={profileImageUrl}
              alt="Profile"
              className="rounded-circle me-2"
              style={{ height: '40px', width: '40px' }}
            />
            <span className="me-3">{fullName || 'Beneficiary'}</span>
          </>
        )}

        <Dropdown>
          <Dropdown.Toggle variant="secondary" id="dropdown-basic">
            <FaUser />
          </Dropdown.Toggle>

          <Dropdown.Menu>
            {/* Only show "Update Profile" for Beneficiaries */}
            {role === 'Beneficiary' && (
              <Dropdown.Item onClick={handleUpdateProfileClick}>
                <FaUserEdit className="me-2" /> Update Profile
              </Dropdown.Item>
            )}
            <Dropdown.Item onClick={handleLogoutClick}>
              <FaSignOutAlt className="me-2" /> Logout
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </div>

      {/* Render success message */}
      {successMessage && (
        <span className="text-success ms-3">{successMessage}</span>
      )}
    </header>
  );
};

export default Header;
