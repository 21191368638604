import React, { useState, useEffect } from 'react';
import { useNavigate,Link } from 'react-router-dom';
import { Form, Button, Row, Col, InputGroup } from 'react-bootstrap';
import { FaEye, FaEyeSlash } from 'react-icons/fa';
import statesCitiesData from '../Components/statesCites.json';
import '../styles/Signup.css';

const Signup = () => {
  const [fullName, setFullName] = useState('');
  const [aadharNumber, setAadharNumber] = useState('');
  const [contactNumber, setContactNumber] = useState('');
  const [alternateContactNumber, setAlternateContactNumber] = useState('');
  const [address, setAddress] = useState('');
  const [city, setCity] = useState('');
  const [customCity, setCustomCity] = useState('');
  const [state, setState] = useState('');
  const [country, setCountry] = useState('India');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [formErrors, setFormErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState('');
  const [error, setError] = useState('');
  const [cities, setCities] = useState([]);
  const navigate = useNavigate();

  const validateForm = () => {
    const errors = {};
    const aadharRegex = /^\d{12}$/;
    const phoneRegex = /^\d{10}$/;
    const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*])[A-Za-z\d!@#$%^&*]{6,10}$/;

    if (!fullName) errors.fullName = 'Full Name is required';
    if (!aadharNumber || !aadharRegex.test(aadharNumber)) errors.aadharNumber = 'Aadhar Number should be exactly 12 digits';
    if (!contactNumber || !phoneRegex.test(contactNumber)) errors.contactNumber = 'Contact Number should be exactly 10 digits';
    if (!alternateContactNumber || !phoneRegex.test(alternateContactNumber)) errors.alternateContactNumber = 'Alternate Contact Number should be exactly 10 digits';
    if (contactNumber === alternateContactNumber) errors.alternateContactNumber = 'Alternate Contact Number should be different from Contact Number';
    if (!address) errors.address = 'Address is required';
    if (!city && !customCity) errors.city = 'City is required';
    if (!state) errors.state = 'State is required';
    if (!country) errors.country = 'Country is required';
    
    if (!password || !passwordRegex.test(password)) {
      errors.password = 'Password must be 6-10 characters long, contain at least one uppercase letter, one lowercase letter, one number, and one special character.';
    }
  
    if (confirmPassword !== password) errors.confirmPassword = 'Passwords do not match';

    return errors;
  };

  const handleStateChange = (e) => {
    const selectedState = e.target.value;
    setState(selectedState);

    if (selectedState) {
      setCities(statesCitiesData['India'][selectedState] || []);
    } else {
      setCities([]);
    }
  };

  const handleCityChange = (e) => {
    const selectedCity = e.target.value;
    if (selectedCity === 'Others') {
      setCity(''); // Clear city selection if 'Others' is selected
      setCustomCity(''); // Clear custom city if 'Others' is selected
    } else {
      setCity(selectedCity);
      setCustomCity(''); // Clear custom city if a predefined city is selected
    }
  };

  const handleCustomCityChange = (e) => {
    setCustomCity(e.target.value);
    setCity(''); // Clear city selection if custom city is being entered
  };

  const handleSignup = async (event) => {
    event.preventDefault();
    setError('');
    setSuccess('');
    setLoading(true);
    const errors = validateForm();
    if (Object.keys(errors).length > 0) {
      setFormErrors(errors);
      setLoading(false);
      return;
    }
    setFormErrors({});

    const signupData = {
      fullName,
      aadharNumber,
      contactNumber: `+91${contactNumber}`,
      alternateContactNumber: `+91${alternateContactNumber}`,
      address,
      city: city === 'Others' ? customCity : city,
      state,
      country,
      password
    };

    try {
      const response = await fetch('http://localhost:5000/api/signup/beneficiary', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(signupData),
      });

      if (response.ok) {
        setSuccess('Signup Successful! Redirecting to login page...');
        setTimeout(() => {
          navigate('/');
        }, 3000);
      } else {
        const errorData = await response.json();
        setError(errorData.message || 'Signup failed. Please try again.');
      }
    } catch (err) {
      console.error('Signup error:', err);
      setError('An error occurred. Please try again later.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className='signup-body'>
      <div className="signup-container">
        <Row className="justify-content-md-center">
          <Col md={12}>
            <h2 className="signup-heading">Sign Up</h2>
            {error && <p className="text-danger">{error}</p>}
            <Form onSubmit={handleSignup}>
              <Form.Group controlId="fullName" className="mb-3">
                <Form.Label>Full Name</Form.Label>
                <Form.Control
                  type="text"
                  value={fullName}
                  placeholder="Enter Full Name"
                  onChange={(e) => setFullName(e.target.value)}
                  isInvalid={!!formErrors.fullName}
                  className="form-control-custom"
                />
                <Form.Control.Feedback type="invalid">
                  {formErrors.fullName}
                </Form.Control.Feedback>
              </Form.Group>

              <Form.Group controlId="aadharNumber" className="mb-3">
                <Form.Label>Aadhar Number</Form.Label>
                <Form.Control
                  type="text"
                  value={aadharNumber}
                  placeholder="Enter Aadhar Number"
                  onChange={(e) => setAadharNumber(e.target.value)}
                  isInvalid={!!formErrors.aadharNumber}
                  className="form-control-custom"
                />
                <Form.Control.Feedback type="invalid">
                  {formErrors.aadharNumber}
                </Form.Control.Feedback>
              </Form.Group>

              <Form.Group controlId="contactNumber" className="mb-3">
                <Form.Label>Contact Number</Form.Label>
                <InputGroup>
                  <InputGroup.Text className='number'>+91</InputGroup.Text>
                  <Form.Control
                    type="text"
                    value={contactNumber}
                    placeholder="Enter Contact Number"
                    onChange={(e) => setContactNumber(e.target.value)}
                    isInvalid={!!formErrors.contactNumber}
                    className="form-control-custom"
                  />
                  <Form.Control.Feedback type="invalid">
                    {formErrors.contactNumber}
                  </Form.Control.Feedback>
                </InputGroup>
              </Form.Group>

              <Form.Group controlId="alternateContactNumber" className="mb-3">
                <Form.Label>Alternate Contact Number</Form.Label>
                <InputGroup>
                  <InputGroup.Text className='number'>+91</InputGroup.Text>
                  <Form.Control
                    type="text"
                    value={alternateContactNumber}
                    placeholder="Enter Alternate Contact Number"
                    onChange={(e) => setAlternateContactNumber(e.target.value)}
                    isInvalid={!!formErrors.alternateContactNumber}
                    className="form-control-custom"
                  />
                  <Form.Control.Feedback type="invalid">
                    {formErrors.alternateContactNumber}
                  </Form.Control.Feedback>
                </InputGroup>
              </Form.Group>

              <Form.Group controlId="country" className="mb-3">
                <Form.Label>Country</Form.Label>
                <Form.Control
                  type="text"
                  value={country}
                  placeholder="Enter Country"
                  onChange={(e) => setCountry(e.target.value)}
                  isInvalid={!!formErrors.country}
                  className="form-control-custom"
                />
                <Form.Control.Feedback type="invalid">
                  {formErrors.country}
                </Form.Control.Feedback>
              </Form.Group>

              <Form.Group controlId="state" className="mb-3">
                <Form.Label>State</Form.Label>
                <Form.Control
                  as="select"
                  value={state}
                  onChange={handleStateChange}
                  isInvalid={!!formErrors.state}
                  className="form-control-custom"
                >
                  <option value="">Select State</option>
                  {Object.keys(statesCitiesData['India'] || {}).map((state, index) => (
                    <option key={index} value={state}>
                      {state}
                    </option>
                  ))}
                </Form.Control>
                <Form.Control.Feedback type="invalid">
                  {formErrors.state}
                </Form.Control.Feedback>
              </Form.Group>

              <Form.Group controlId="city" className="mb-3">
                <Form.Label>City</Form.Label>
                <Form.Control
                  as="select"
                  value={city}
                  onChange={handleCityChange}
                  isInvalid={!!formErrors.city}
                  className="form-control-custom"
                >
                  <option value="">Select City</option>
                  {cities.map((city, index) => (
                    <option key={index} value={city}>
                      {city}
                    </option>
                  ))}
                  <option value="Others">Others</option>
                </Form.Control>
                <Form.Control.Feedback type="invalid">
                  {formErrors.city}
                </Form.Control.Feedback>
                {city === 'Others' && (
                  <Form.Control
                    type="text"
                    placeholder="Enter Custom City Name"
                    value={customCity}
                    onChange={handleCustomCityChange}
                    isInvalid={!!formErrors.city}
                    className="form-control-custom mt-2"
                  />
                )}
              </Form.Group>

              <Form.Group controlId="address" className="mb-3">
                <Form.Label>Address</Form.Label>
                <Form.Control
                  type="text"
                  as="textarea"
                  value={address}
                  placeholder="Enter Address"
                  onChange={(e) => setAddress(e.target.value)}
                  isInvalid={!!formErrors.address}
                  className="form-control-custom"
                />
                <Form.Control.Feedback type="invalid">
                  {formErrors.address}
                </Form.Control.Feedback>
              </Form.Group>

              <Form.Group controlId="password" className="mb-3">
                <Form.Label>Password</Form.Label>
                <InputGroup>
                  <Form.Control
                    type={showPassword ? 'text' : 'password'}
                    value={password}
                    placeholder="Enter Password"
                    onChange={(e) => setPassword(e.target.value)}
                    isInvalid={!!formErrors.password}
                    className="form-control-custom"
                  />
                  <InputGroup.Text className="number" onClick={() => setShowPassword(!showPassword)}>
                    {showPassword ? <FaEyeSlash /> : <FaEye />}
                  </InputGroup.Text>
                  <Form.Control.Feedback type="invalid">
                    {formErrors.password}
                  </Form.Control.Feedback>
                </InputGroup>
              </Form.Group>

              <Form.Group controlId="confirmPassword" className="mb-3">
                <Form.Label>Confirm Password</Form.Label>
                <InputGroup>
                  <Form.Control
                    type={showConfirmPassword ? 'text' : 'password'}
                    value={confirmPassword}
                    placeholder="Confirm Password"
                    onChange={(e) => setConfirmPassword(e.target.value)}
                    isInvalid={!!formErrors.confirmPassword}
                    className="form-control-custom"
                  />
                  <InputGroup.Text className='number' onClick={() => setShowConfirmPassword(!showConfirmPassword)}>
                    {showConfirmPassword ? <FaEyeSlash /> : <FaEye />}
                  </InputGroup.Text>
                  <Form.Control.Feedback type="invalid">
                    {formErrors.confirmPassword}
                  </Form.Control.Feedback>
                </InputGroup>
              </Form.Group>

              <Button variant="primary" type="submit" disabled={loading}>
                {loading ? 'Signing Up...' : 'Sign Up'}
              </Button>

              {success && <p className="text-success mt-3">{success}</p>}
             
              <p className="text-center">
                Already have  account? <Link to="/" className="link-custom">Login</Link>
              </p>
            </Form>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default Signup;
