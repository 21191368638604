import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import {Col , Row,Form} from 'react-bootstrap';
import "../styles/ForgotPassword.css";
const ForgotPassword = () => {
  const [aadharNumber, setAadharNumber] = useState('');
  const [contactNumber, setContactNumber] = useState('');
  const [message, setMessage] = useState('');
  const navigate = useNavigate(); // useNavigate hook to handle navigation

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post('http://localhost:5000/api/forgot-password', {
        aadharNumber,
        contactNumber,
      });
      setMessage(response.data.message);

      // Redirect to /verify-otp after successful OTP send
      navigate('/verify-otp');
    } catch (error) {
      setMessage(error.response?.data?.message || 'An error occurred');
    }
  };

  return (
    <div className="forgot-body">
    <div className="Forgot-container">
      <Row className='justify-content-md-center '>
      <Col md={12}>
      <h2 className="Forgot-heading">Forgot Password</h2>
      <Form onSubmit={handleSubmit}>
        <Form.Group className="form-group">
        <Form.Label>Aadhar Number</Form.Label>
        <Form.Control
            type="text"
            className="form-control-custom"
            value={aadharNumber}
            onChange={(e) => setAadharNumber(e.target.value)}
            required
          />
        </Form.Group>
        <Form.Group className="form-group">
        <Form.Label>  Contact Number</Form.Label>
        <Form.Control
            type="text"
            className="form-control-custom"
            value={contactNumber}
            onChange={(e) => setContactNumber(e.target.value)}
            required
          />
        </Form.Group>
        <button type="submit" className="btn btn-primary">
          Send OTP
        </button>
      </Form>
      </Col>
      {message && <p>{message}</p>}
    </Row>
    </div>
    </div>
  );
};

export default ForgotPassword;
