import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Modal, Form, Button, Alert, Row, Col } from 'react-bootstrap';
import { FaEdit } from 'react-icons/fa';
import DataTable from 'react-data-table-component';


import './Admin.css'; // Ensure to create this CSS file for additional styling

const ManageBeneficiaries = () => {
  const [beneficiaries, setBeneficiaries] = useState([]);
  const [filteredBeneficiaries, setFilteredBeneficiaries] = useState([]);
  const [show, setShow] = useState(false);
  const [currentBeneficiary, setCurrentBeneficiary] = useState({});
  const [errors, setErrors] = useState({});
  const [showAlert, setShowAlert] = useState(false);
  const [search, setSearch] = useState('');

  useEffect(() => {
    fetchAllBeneficiaries();
  }, []);

  useEffect(() => {
    const result = beneficiaries.filter(b =>
      (b.fullName && b.fullName.toLowerCase().includes(search.toLowerCase())) ||
      (b.contactNumber && b.contactNumber.toLowerCase().includes(search.toLowerCase())) ||
      (b.aadharNumber && b.aadharNumber.toLowerCase().includes(search.toLowerCase())) ||
      (b.city && b.city.toLowerCase().includes(search.toLowerCase())) ||
      (b.state && b.state.toLowerCase().includes(search.toLowerCase())) ||
      (b.status && b.status.toLowerCase().includes(search.toLowerCase()))
    );
    setFilteredBeneficiaries(result);
  }, [search, beneficiaries]);
  

  const fetchAllBeneficiaries = async () => {
    try {
      const response = await axios.get('http://localhost:5000/api/beneficiaries', {
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
      });
      setBeneficiaries(response.data.beneficiaries);
    } catch (error) {
      console.error('Error fetching beneficiaries:', error);
      setBeneficiaries([]);
    }
  };

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleEdit = (beneficiary) => {
    setCurrentBeneficiary(beneficiary);
    handleShow();
  };

  const validateBeneficiary = () => {
    let formErrors = {};

    if (!currentBeneficiary.fullName) {
      formErrors.fullName = 'Full Name is required';
    } else if (!/^[a-zA-Z\s]+$/.test(currentBeneficiary.fullName)) {
      formErrors.fullName = 'Full Name should contain only letters and spaces';
    }

    if (!currentBeneficiary.contactNumber) {
      formErrors.contactNumber = 'Contact Number is required';
    } else if (!/^\d{10}$/.test(currentBeneficiary.contactNumber)) {
      formErrors.contactNumber = 'Contact Number should be exactly 10 digits';
    }

    if (currentBeneficiary.alternateContactNumber && !/^\d{10}$/.test(currentBeneficiary.alternateContactNumber)) {
      formErrors.alternateContactNumber = 'Alternate Contact Number should be exactly 10 digits';
    }

    if (!currentBeneficiary.aadharNumber) {
      formErrors.aadharNumber = 'Aadhar Number is required';
    } else if (!/^\d{12}$/.test(currentBeneficiary.aadharNumber)) {
      formErrors.aadharNumber = 'Aadhar Number should be exactly 12 digits';
    }

    if (!currentBeneficiary.city) {
      formErrors.city = 'City is required';
    } else if (/^\d+$/.test(currentBeneficiary.city)) {
      formErrors.city = 'City should not be a number';
    }

    if (!currentBeneficiary.state) {
      formErrors.state = 'State is required';
    } else if (!/^[a-zA-Z\s]+$/.test(currentBeneficiary.state)) {
      formErrors.state = 'State should only contain letters';
    }

    if (!currentBeneficiary.country) {
      formErrors.country = 'Country is required';
    } else if (!/^[a-zA-Z\s]+$/.test(currentBeneficiary.country)) {
      formErrors.country = 'Country should only contain letters';
    }

    if (!currentBeneficiary.address) {
      formErrors.address = 'Address is required';
    }

    setErrors(formErrors);
    return Object.keys(formErrors).length === 0;
  };

  const handleSave = async () => {
    if (validateBeneficiary()) {
      try {
        const response = await axios.put(`http://localhost:5000/api/beneficiaries/update/${currentBeneficiary._id}`, currentBeneficiary, {
          headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
        });
        setBeneficiaries(beneficiaries.map(b => (b._id === currentBeneficiary._id ? currentBeneficiary : b)));
        handleClose();
        setShowAlert(true);
      } catch (error) {
        console.error('Error saving beneficiary:', error);
      }
    }
  };

  const handleStatusChange = async (beneficiary) => {
    try {
      const newStatus = !beneficiary.isActive;
      await axios.post(`http://localhost:5000/api/beneficiaries/update/status/${beneficiary._id}`, { isActive: newStatus }, {
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
      });
      setBeneficiaries(beneficiaries.map(b => (b._id === beneficiary._id ? { ...b, isActive: newStatus } : b)));
    } catch (error) {
      console.error('Error updating status:', error);
    }
  };

  const columns = [
    {
      name: 'SNo',
      cell: (row, index) => index + 1,
      sortable: false
    },
    {
      name: 'Full Name',
      selector: row => row.fullName,
      sortable: true
    },
    {
      name: 'Contact Number',
      selector: row => row.contactNumber,
      sortable: true
    },
    {
      name: 'Aadhar Number',
      selector: row => row.aadharNumber,
      sortable: true
    },
    {
      name: 'City',
      selector: row => row.city,
      sortable: true
    },
    {
      name: 'State',
      selector: row => row.state,
      sortable: true
    },
    {
      name: 'Country',
      selector: row => row.country,
      sortable: true
    },
    {
      name: 'Status',
      cell: row => (
        <span
          onClick={() => handleStatusChange(row)}
          style={{
            color: row.isActive ? 'green' : 'red',
            cursor: 'pointer',
            fontWeight: 'bold'
          }}
        >
          {row.isActive ? 'Active' : 'Inactive'}
        </span>
      ),
      sortable: true
    },
    {
      name: 'Actions',
      cell: row => (
        <Button variant="link" onClick={() => handleEdit(row)}>
          <FaEdit size={16} />
        </Button>
      ),
      sortable: false
    }
  ];

  return (
    <div className="manage-beneficiaries">
      {showAlert && <Alert variant="success" onClose={() => setShowAlert(false)} dismissible>
        Beneficiary saved successfully!
      </Alert>}
      
      <Form.Group controlId="search">
        <Form.Control
          type="text"
          placeholder="Search Beneficiaries"
          value={search}
          onChange={(e) => setSearch(e.target.value)}
        />
      </Form.Group>

      <DataTable
        columns={columns}
        data={filteredBeneficiaries}
        pagination
        paginationPerPage={8}
        paginationRowsPerPageOptions={[8, 10, 50]}
        defaultSortFieldId={1}
        defaultSortAsc={true}
        responsive
        className="data-table"
      />

      {/* Modal for editing beneficiary */}
      <Modal show={show} onHide={handleClose} centered size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Edit Beneficiary</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Row>
            <Col md={6}>
            <Form.Group  controlId="fullName">
              <Form.Label>Full Name</Form.Label>
              
                <Form.Control
                  type="text"
                  value={currentBeneficiary.fullName || ''}
                  onChange={(e) => setCurrentBeneficiary({ ...currentBeneficiary, fullName: e.target.value })}
                />
                {errors.fullName && <Form.Text className="text-danger">{errors.fullName}</Form.Text>}
            
            </Form.Group>
            </Col>
            <Col md={6}>
            <Form.Group controlId="aadharNumber">
              <Form.Label column sm="4">Aadhar Number</Form.Label>
              
                <Form.Control
                  type="text"
                  value={currentBeneficiary.aadharNumber || ''}
                  onChange={(e) => setCurrentBeneficiary({ ...currentBeneficiary, aadharNumber: e.target.value })}
                />
                {errors.aadharNumber && <Form.Text className="text-danger">{errors.aadharNumber}</Form.Text>}
             
            </Form.Group>
            </Col>
            </Row>
            <Row>
            <Col md={6}>
            <Form.Group  controlId="contactNumber">
              <Form.Label >Contact Number</Form.Label>
               <Form.Control
                  type="text"
                  value={currentBeneficiary.contactNumber || ''}
                  onChange={(e) => setCurrentBeneficiary({ ...currentBeneficiary, contactNumber: e.target.value })}
                />
                {errors.contactNumber && <Form.Text className="text-danger">{errors.contactNumber}</Form.Text>}
              
            </Form.Group>
            </Col>
            <Col>
            <Form.Group  controlId="alternateContactNumber">
              <Form.Label>Alternate Contact Number</Form.Label>
              
                <Form.Control
                  type="text"
                  value={currentBeneficiary.alternateContactNumber || ''}
                  onChange={(e) => setCurrentBeneficiary({ ...currentBeneficiary, alternateContactNumber: e.target.value })}
                />
                {errors.alternateContactNumber && <Form.Text className="text-danger">{errors.alternateContactNumber}</Form.Text>}
             
            </Form.Group>
            </Col>
            </Row>
            <Row>
            <Form.Group  controlId="country">
              <Form.Label>Country</Form.Label>
              
                <Form.Control
                  type="text"
                  value={currentBeneficiary.country || ''}
                  onChange={(e) => setCurrentBeneficiary({ ...currentBeneficiary, country: e.target.value })}
                />
                {errors.country && <Form.Text className="text-danger">{errors.country}</Form.Text>}
              
            </Form.Group>
              
            
          
            <Col>
            <Form.Group  controlId="city">
              <Form.Label>City</Form.Label>
              
                <Form.Control
                  type="text"
                  value={currentBeneficiary.city || ''}
                  onChange={(e) => setCurrentBeneficiary({ ...currentBeneficiary, city: e.target.value })}
                />
                {errors.city && <Form.Text className="text-danger">{errors.city}</Form.Text>}
              
            </Form.Group>
            </Col>
            </Row>
            <Row>
            <Col>
            <Form.Group controlId="state">
              <Form.Label>State</Form.Label>
              
                <Form.Control
                  type="text"
                  value={currentBeneficiary.state || ''}
                  onChange={(e) => setCurrentBeneficiary({ ...currentBeneficiary, state: e.target.value })}
                />
                {errors.state && <Form.Text className="text-danger">{errors.state}</Form.Text>}
              
            </Form.Group>
            </Col>
            </Row>
           
            <Form.Group  controlId="address">
              <Form.Label>Address</Form.Label>
              
                <Form.Control
                  type="text"
                  value={currentBeneficiary.address || ''}
                  onChange={(e) => setCurrentBeneficiary({ ...currentBeneficiary, address: e.target.value })}
                />
                {errors.address && <Form.Text className="text-danger">{errors.address}</Form.Text>}
              
            </Form.Group>
          
  {/* Father Section */}
  
  <Form.Group controlId="fatherName">
    <Form.Label>Father Name</Form.Label>
    
      <Form.Control
        type="text"
        value={currentBeneficiary.fatherName || ''}
        placeholder='Enter Father Name'
        onChange={(e) => setCurrentBeneficiary({ ...currentBeneficiary, fatherName: e.target.value })}
      />
    
  </Form.Group>
   
   <Row>
    <Col>
  <Form.Group  controlId="fatherContact">
    <Form.Label>Father Contact Number</Form.Label>
    
      <Form.Control
        type="text"
        value={currentBeneficiary.
          fatherContactNumber || ''}
         placeholder='Enter Father Contact Number'
        onChange={(e) => setCurrentBeneficiary({ ...currentBeneficiary, 
          fatherContactNumber: e.target.value })}
      />
    
  </Form.Group>
  </Col>
  <Col>
  <Form.Group  controlId="fatherAadhar">
    <Form.Label>Father Aadhar Number</Form.Label>
    
      <Form.Control
        type="text"
        value={currentBeneficiary.
          fatherAadharNumber || ''}
         placeholder='Enter Father Aadhar Number'
        onChange={(e) => setCurrentBeneficiary({ ...currentBeneficiary,
          fatherAadharNumber : e.target.value })}
      />
    
  </Form.Group>
  </Col>

  </Row>

  {/* Mother Section */}
  <Form.Group  controlId="motherName">
    <Form.Label column sm="4">Mother Name</Form.Label>
    
      <Form.Control
        type="text"
        value={currentBeneficiary.
          motherName
           || ''}
         placeholder='Enter Mother Name'
        onChange={(e) => setCurrentBeneficiary({ ...currentBeneficiary, 
          motherName
          : e.target.value })}
      />
    
  </Form.Group>
  <Row>
  <Col>
  <Form.Group  controlId="
motherContactNumber">
    <Form.Label >Mother Contact Number</Form.Label>
    
      <Form.Control
        type="text"
        value={currentBeneficiary.
          motherContactNumber || ''}
         placeholder='Enter Mother Contact Number'
        onChange={(e) => setCurrentBeneficiary({ ...currentBeneficiary, 
          motherContactNumber: e.target.value })}
      />
    
  </Form.Group>
  </Col>
<Col>
  <Form.Group  controlId="
motherAadharNumber">
    <Form.Label>Mother Aadhar Number</Form.Label>
  
      <Form.Control
        type="text"
        value={currentBeneficiary.
          motherAadharNumber || ''}
         placeholder='Enter Mother Aadhar Number'
        onChange={(e) => setCurrentBeneficiary({ ...currentBeneficiary, 
          motherAadharNumber: e.target.value })}
      />
    
  </Form.Group>
  </Col>
  </Row>

  {/* Brother Section */}
  <Form.Group  controlId="
brotherName">
    <Form.Label>Brother Name</Form.Label>
    
      <Form.Control
        type="text"
        value={currentBeneficiary.brotherName || ''}
         placeholder='Enter Bother Name'
        onChange={(e) => setCurrentBeneficiary({ ...currentBeneficiary, brotherName: e.target.value })}
      />
  
  </Form.Group>
  <Row>
  <Col>
  <Form.Group  controlId="
brotherContactNumber">
    <Form.Label >Brother Contact Number</Form.Label>
    
      <Form.Control
        type="text"
        value={currentBeneficiary.
          brotherContactNumber || ''}
         placeholder='Enter Bother Contact Number'
        onChange={(e) => setCurrentBeneficiary({ ...currentBeneficiary, 
          brotherContactNumber: e.target.value })}
      />
  
  </Form.Group>
    </Col>
    <Col>

  <Form.Group controlId="
brotherAadharNumber">
    <Form.Label>Brother Aadhar Number</Form.Label>
    
      <Form.Control
        type="text"
        value={currentBeneficiary.
          brotherAadharNumber || ''}
         placeholder='Enter Bother Aadhar Number'
        onChange={(e) => setCurrentBeneficiary({ ...currentBeneficiary, 
          brotherAadharNumber: e.target.value })}
      />
   
  </Form.Group>
   </Col>
   </Row>

  {/* Sister Section */}
  
  <Form.Group  controlId="sisterName">
    <Form.Label column sm="4">Sister Name</Form.Label>
    
      <Form.Control
        type="text"
        value={currentBeneficiary.
          sisterName || ''}
         placeholder='Enter Sister Name'
        onChange={(e) => setCurrentBeneficiary({ ...currentBeneficiary, 
          sisterName: e.target.value })}
      />
    
  </Form.Group>
  <Row>
  <Col>
  <Form.Group  controlId="
sisterContactNumber">
    <Form.Label >Sister Contact Number</Form.Label>
    
      <Form.Control
        type="text"
        value={currentBeneficiary.
          sisterContactNumber || ''}
         placeholder='Enter Sister Contact Number'
        onChange={(e) => setCurrentBeneficiary({ ...currentBeneficiary, 
          sisterContactNumber: e.target.value })}
      />
   
  </Form.Group>
  </Col>
  <Col>
  <Form.Group controlId="
sisterAadharNumber">
    <Form.Label >Sister Aadhar Number</Form.Label>
   
      <Form.Control
        type="text"
        value={currentBeneficiary.
          sisterAadharNumber || ''}
         placeholder='Enter Sister Aadhar Number'
        onChange={(e) => setCurrentBeneficiary({ ...currentBeneficiary, 
          sisterAadharNumber: e.target.value })}
      />
    
  </Form.Group>
  </Col>
  </Row>
  {/* Children Section */}
  <Row>
  <Col>
  <Form.Group controlId="childName1">
    <Form.Label>Child Name 1</Form.Label>
  
      <Form.Control
        type="text"
        value={currentBeneficiary.childName1 || ''}
         placeholder='Enter Sister Child Name 1'
        onChange={(e) => setCurrentBeneficiary({ ...currentBeneficiary, childName1: e.target.value })}
      />
    
  </Form.Group>
  </Col>
<Col>
  <Form.Group as={Row} controlId="childName2">
    <Form.Label>Child Name 2</Form.Label>
  
      <Form.Control
        type="text"
        value={currentBeneficiary.childName2 || ''}
         placeholder='Enter Child Name 2'
        onChange={(e) => setCurrentBeneficiary({ ...currentBeneficiary, childName2: e.target.value })}
      />
    
  </Form.Group>
  </Col>
  </Row>
  {/* Profession & Income Section */}
  <Row>
  <Col>
  <Form.Group  controlId="profession">
    <Form.Label>Profession</Form.Label>
    
      <Form.Control
        type="text"
        value={currentBeneficiary.profession || ''}
         placeholder='Enter Profession'
        onChange={(e) => setCurrentBeneficiary({ ...currentBeneficiary, profession: e.target.value })}
      />
    
  </Form.Group>
  </Col>
  <Col>
  <Form.Group controlId="designation">
    <Form.Label>Designation</Form.Label>
   
      <Form.Control
        type="text"
        value={currentBeneficiary.designation || ''}
         placeholder='Enter designation'
        onChange={(e) => setCurrentBeneficiary({ ...currentBeneficiary, designation: e.target.value })}
      />
    
  </Form.Group>
  </Col>
  </Row>
  <Row>
   <Col >
  <Form.Group  controlId="organization">
    <Form.Label>Organization</Form.Label>
   
      <Form.Control
        type="text"
        value={currentBeneficiary.organization || ''}
         placeholder='Enter Organization Name'
        onChange={(e) => setCurrentBeneficiary({ ...currentBeneficiary, organization: e.target.value })}
      />
    
  </Form.Group>
  </Col>
<Col>
  <Form.Group controlId="organizationAddress">
    <Form.Label column>Organization Address</Form.Label>
    
      <Form.Control
        type="text"
        value={currentBeneficiary.organizationAddress || ''}
         placeholder='Enter Organization Address'
        onChange={(e) => setCurrentBeneficiary({ ...currentBeneficiary, organizationAddress: e.target.value })}
      />
    
  </Form.Group>
  </Col>
  </Row>
  <Row>
  <Col>
  <Form.Group  controlId="annualIncome">
    <Form.Label>Annual Income</Form.Label>
    
      <Form.Control
        type="text"
        value={currentBeneficiary.annualIncome || ''}
         placeholder='Enter Annual Income'
        onChange={(e) => setCurrentBeneficiary({ ...currentBeneficiary, annualIncome: e.target.value })}
      />
  
  </Form.Group>
    </Col>
<Col>
  <Form.Group  controlId="monthlyIncome">
    <Form.Label>Monthly Income</Form.Label>
    
      <Form.Control
        type="text"
        value={currentBeneficiary.monthlyIncome || ''}
         placeholder='Enter Monthly Income'
        onChange={(e) => setCurrentBeneficiary({ ...currentBeneficiary, monthlyIncome: e.target.value })}
      />
   
  </Form.Group>
   </Col>
</Row>
  <Form.Group  controlId="loanDetails">
    <Form.Label column sm="4">Loan Details</Form.Label>
    
      <Form.Control
        type="text"
        value={currentBeneficiary.loanDetails || ''}
         placeholder='Enter Loan Details'
        onChange={(e) => setCurrentBeneficiary({ ...currentBeneficiary,loanDetails: e.target.value })}
      />
    
  </Form.Group>

  {/* Additional Scheme Details */}
  <Row>
  <Col>
  <Form.Group  controlId="ayushmanCardDetails">
    <Form.Label>Ayushman Card Details</Form.Label>
  
      <Form.Control
        type="text"
        value={currentBeneficiary.ayushmanCardDetails || ''}
         placeholder='Enter Ayushman Card Details'
        onChange={(e) => setCurrentBeneficiary({ ...currentBeneficiary, ayushmanCardDetails: e.target.value })}
      />
   
  </Form.Group>
   </Col>
   <Col>
  <Form.Group controlId="
widowPensionDetails">
    <Form.Label >Widow Scheme Details</Form.Label>
    
      <Form.Control
        type="text"
        value={currentBeneficiary.
          widowPensionDetails || ''}
         placeholder='Enter Widow Scheme Details '
        onChange={(e) => setCurrentBeneficiary({ ...currentBeneficiary, 
          widowPensionDetails: e.target.value })}
      />
    
  </Form.Group>
  </Col>
    </Row>
    <Row>
     <Col>
  <Form.Group  controlId="
seniorCitizenPensionDetails">
    <Form.Label>Senior Citizen Scheme Details</Form.Label>
      <Form.Control
        type="text"
        value={currentBeneficiary.
          seniorCitizenPensionDetails || ''}
         placeholder='Enter Senior Citizen Scheme Details'
        onChange={(e) => setCurrentBeneficiary({ ...currentBeneficiary, 
          seniorCitizenPensionDetails: e.target.value })}
      />
  </Form.Group>
    </Col>
    <Col>
   
  <Form.Group  controlId="governmentSchemeDetails">
    <Form.Label>Government Scheme Details</Form.Label>
  
      <Form.Control
        type="text"
        value={currentBeneficiary.governmentSchemeDetails || ''}
         placeholder='Enter Government Scheme Details'
        onChange={(e) => setCurrentBeneficiary({ ...currentBeneficiary, governmentSchemeDetails: e.target.value })}
      />
    
  </Form.Group>
  </Col>
  </Row>

          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>Close</Button>
          <Button variant="primary" onClick={handleSave}>Save Changes</Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default ManageBeneficiaries;
