import React from 'react';
import { Card, Row, Col, Container } from 'react-bootstrap';
import { FaUser, FaUsers } from 'react-icons/fa';
import Header from '../Components/Header';
import '../styles/Home.css'; // Import your custom CSS file

const HomePage = () => {
  return (
    <>
      <Header />
      <Container className="d-flex justify-content-center align-items-center min-vh-100">
        <Row className="text-center">
          <Col xs={12} md={4} className="mb-4">
            <Card className="text-center bg-primary text-white">
              <Card.Body>
                <FaUser size={50} />
                <Card.Title className="mt-3">Manage Donors</Card.Title>
              </Card.Body>
            </Card>
          </Col>
          
          <Col xs={12} md={4} className="mb-4">
            <Card className="text-center bg-success text-white">
              <Card.Body>
                <FaUsers size={50} />
                <Card.Title className="mt-3">Manage Beneficiaries</Card.Title>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default HomePage;
